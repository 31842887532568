
const endpoint = 'https://us-central1-serene-auto.cloudfunctions.net/vin-scraper'

async function main(body) {
    try {
        const response = await fetch(endpoint + '/main', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        let content = await response.json();
        return content;
    } catch (error) {
        console.error(`Request to vin-scraper function error: ${error.message}`);
        throw Error('error on getting data from server' )
    }
}

export default main