import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams} from 'react-router-dom';
import { db } from './firebase';

const StepOne = ({ vehicleDetails }) => {
  const [vehicleInformation, setVehicleInformation] = useState({});
  const [inquiry, setInquiry] = useState();
  const [inquiryExists, setInquiryExists] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    db.collection('inquiries').doc(id).onSnapshot(doc => {
      setInquiry(doc.data());
      if(doc.data() && doc.data().vehicleInformation !== undefined) {
        setVehicleInformation(doc.data().vehicleInformation);
        setInquiryExists(true);
      } else {
        setVehicleInformation({
          stockNumber: vehicleDetails.stockNumber || '',
          vin: vehicleDetails.VIN || '',
          year: vehicleDetails.year || '',
          make: vehicleDetails.make || '',
          model: vehicleDetails.model || '',
          trim: vehicleDetails.trim || '',
          dateVehicleSold: vehicleDetails.dateVehicleSold || '',
          purchasePrice: vehicleDetails.askingPrice || '',
          isCommercialUse: vehicleDetails.isCommercialUse || false,
          isBrandedOrRebuilt: vehicleDetails.isBrandedOrRebuilt || false,
          inServiceDate: vehicleDetails.inServiceDate || '',
          odometerReading: vehicleDetails.vehicleKilometers || '',
        });
      }
    })
  }, [vehicleDetails]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setVehicleInformation((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(inquiryExists === false) {
      db.collection('inquiries').add({
        vehicleInformation
      }).then((docRef) => {
        console.log('Document added with ID: ', docRef.id);
        navigate(`/warranty/${docRef.id}/programs`);
      })
    } else {
      db.collection('inquiries').doc(id).update({
        vehicleInformation: vehicleInformation
      })
      navigate(`/warranty/${id}/programs`);
    }
  };

  return (
    <>
      <div className="warranty-progress-tracker">
        <div className="warranty-progress-selected">VEHICLE INFORMATION</div>
        <div>/</div>
        <div>PROGRAMS</div>
        <div>/</div>
        <div>CUSTOMER INFORMATION</div>
        <div>/</div>
        <div>SUMMARY</div>
      </div>

      {/* <div className="inventory-title" style={{paddingBottom: "20px"}}>Vehicle Information</div> */}
      <form onSubmit={handleSubmit} className="warranty-form" style={{paddingBottom: '270px'}}>
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="stockNumber"
              value={vehicleInformation.stockNumber}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Stock Number</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="vin"
              value={vehicleInformation.vin}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>VIN</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="make"
              value={vehicleInformation.make}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Make</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="model"
              value={vehicleInformation.model}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Model</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="trim"
              value={vehicleInformation.trim}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Trim</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="year"
              value={vehicleInformation.year}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Year</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="number"
              className="form-control"
              name="purchasePrice"
              value={vehicleInformation.purchasePrice}
              onChange={handleChange}
              placeholder=" "
              step="0.01"
              required
            />
            <label>Purchase Price / Lease Value ($)</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="odometerReading"
              value={vehicleInformation.odometerReading}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Odometer Reading (KMs)</label>
          </div>
        </div>
        
        <div className="custom-warranty-button-container" style={{justifyContent: 'flex-end'}}>
          <Button className="custom-next-button financing-button" type="submit">Continue</Button>
        </div>
      </form>
    </>
  );
};

export default StepOne;
