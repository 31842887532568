import firebase from "firebase";

const prodCofing = {
    apiKey: "AIzaSyBXOlHSQbeowP-5s1JvWLNOQ7GUcv7OjZ0",
    authDomain: "serene-auto.firebaseapp.com",
    projectId: "serene-auto",
    appId: "1:1044478490187:web:12e30750c6e03fc5883fce",
    storageBucket: "serene-auto.appspot.com",
};

const devConfig = {
    apiKey: "AIzaSyCmomAIKHhuUG2K5befYZfjnyhT9MQKFUU",
    authDomain: "serene-dev-4f5bf.firebaseapp.com",
    projectId: "serene-dev-4f5bf",
    storageBucket: "serene-dev-4f5bf.appspot.com",
    messagingSenderId: "1042942126831",
    appId: "1:1042942126831:web:4ff6a2a82276a28f0d091a"
};


const app = firebase.initializeApp(prodCofing);

const db = app.firestore();
const auth = app.auth();
const storage = firebase.storage();

export { auth, db, firebase, storage }; 