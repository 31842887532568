import _ from 'lodash';
// const _ = require('lodash');
// const inventory = require('./final-1706347409400.json');

const map = {
    Base: {
        'Model Year': ['faxvin.Basic.Model Year', 'kbb.main.year', 'nhtsa.ModelYear'],
        'Make': ['faxvin.Basic.Make', 'kbb.main.make', 'nhtsa.Make'],
        'Model': ['faxvin.Basic.Model', 'kbb.main.model', 'nhtsa.Model'],
        'Trim Level': ['faxvin.Basic.Trim Level', 'kbb.main.trim', 'nhtsa.Trim', 'nhtsa.Trim2'],
        'Manufactured in': ['faxvin.Basic.Manufactured in', 'nhtsa.PlantCountry'],
        'Body Style': ['faxvin.Basic.Body Style', 'kbb.main.trim.bodyStyle', 'nhtsa.BodyClass'],
        'Doors': ['nhtsa.Doors'],
    },
    Drivetrain: {
        'Engine Type': ['faxvin.Drivetrain.Engine Type', 'kbb.main.Engine', 'nhtsa.EngineConfiguration,EngineCylinders'],
        'Transmission': ['faxvin.Drivetrain.Transmission-short', 'faxvin.Drivetrain.Transmission-long', 'faxvin.Drivetrain.Transmission-shortVariable', 'faxvin.Drivetrain.Transmission-longVariable', 'kbb.main.Transmission', 'nhtsa.TransmissionSpeeds', 'nhtsa.TransmissionStyle'],
        'Driveline': ['faxvin.Drivetrain.Driveline', 'kbb.main.Drivetrain', 'nhtsa.DriveType'],
        'Traction Control': ['faxvin.Drivetrain.Traction Control', 'kbb.Braking and Traction.Traction Control', 'nhtsa.TractionControl'],
        'Vehicle Stability Control System': ['faxvin.Drivetrain.Vehicle Stability Control System', 'kbb.Braking and Traction.Stability Control'],
    },
    Seating: {
        'Seats': ['faxvin.Seating.Standard Seating', 'faxvin.Seating.Optional Seating', 'nhtsa.Seats'],
        'Driver Multi-Adjustable Power Seat': ['faxvin.Seating.Driver Multi-Adjustable Power Seat', 'kbb.Seats.Power Seat,kbb.Seats.Dual Power Seats'],
        'Passenger Multi-Adjustable Power Seat': ['faxvin.Seating.Passenger Multi-Adjustable Power Seat', 'kbb.Seats.Dual Power Seats'],
        'Leather Seat': ['faxvin.Seating.Leather Seat', 'kbb.Seats.Leather'],
        'Front Heated Seat': ['faxvin.Seating.Front Heated Seat', 'kbb.Seats.Heated Seats'],
    },
    Fuel: {
        'Fuel Type': ['nhtsa.FuelTypePrimary', 'nhtsa.FuelTypeSecondary'],
        'Fuel Economy-city': ['faxvin.Fuel.Fuel Economy-city', 'faxvin.Fuel.Fuel Economy City'],
        'Fuel Economy-highway': ['faxvin.Fuel.Fuel Economy-highway', 'faxvin.Fuel.Fuel Economy Highway']
    },
    Braking: {
        'ABS': ['faxvin.Braking.ABS Brakes', 'kbb.Braking and Traction.ABS (4-Wheel)', 'nhtsa.ABS']
    },
    'Wheels and Tires': {
        'Alloy Wheels': ['faxvin.Wheels and Tires.Alloy Wheels', 'kbb.Wheels and Tires.Alloy Wheels'],
        'Steel Wheels': ['faxvin.Wheels and Tires.Steel Wheels', 'kbb.Wheels and Tires.Steel Wheels']
    },
    Dimensions: {
        'Wheelbase': ['faxvin.Dimensions.Wheelbase', 'nhtsa.WheelBaseShort', 'nhtsa.WheelBaseLong']
    },
    Weight: {
        'GVWR Class': ['nhtsa.GVWR']
    },
    Comfort: {
        'Air Conditioning': ['faxvin.Comfort.Air Conditioning', 'kbb.Comfort and Convenience.Air Conditioning']
    },
    Safety: {
        'Power Door Locks': ['faxvin.Safety.Power Door Locks', 'kbb.Comfort and Convenience.Power Door Locks'],
        'Keyless Entry': ['faxvin.Safety.Keyless Entry', 'kbb.Comfort and Convenience.Keyless Entry'],
        'Park Assist': ['faxvin.Safety.Electronic Parking Aid', 'kbb.Safety and Security.Park Assist'],
        'Remote Ignition': ['faxvin.Safety.Remote Ignition', 'kbb.Comfort and Convenience.Remote Engine Start'],
        'AirBag Curtain Location': ['nhtsa.AirBagLocCurtain'],
        'AirBag Location Front': ['nhtsa.AirBagLocFront'],
        'AirBag Location Knee': ['nhtsa.AirBagLocKnee'],
        'AirBag Location Seat Cushion': ['nhtsa.AirBagLocSeatCushion'],
        'AirBag Location Side': ['nhtsa.AirBagLocSide'],
    },
    Lighting: {
        'Fog Lights': ['faxvin.Lighting.Fog Lights', 'kbb.Exterior.Fog Lights'],
        'Daytime Running Lights': ['faxvin.Lighting.Daytime Running Lights', 'kbb.Lighting.Daytime Running Lights'],
        'High Intensity Discharge Headlights': ['faxvin.Lighting.High Intensity Discharge Headlights', 'kbb.Lighting.HID Headlamps'],
    },
    Entertainment: {
        'AM/FM Radio': ['faxvin.Entertainment.AM/FM Radio', 'kbb.Entertainment and Instrumentation.AM/FM/HD Radio', 'kbb.Entertainment and Instrumentation.AM/FM Radio w/MyLink'],
        'CD Player': ['faxvin.Entertainment.CD Player', 'kbb.Entertainment and Instrumentation.CD/MP3 (Single Disc)', 'kbb.Entertainment and Instrumentation.CD/MP3 (Multi Disc)'],
        'DVD Player': ['faxvin.Entertainment.DVD Player', 'kbb.Entertainment and Instrumentation.DVD System'],
        'Navigation Aid': ['faxvin.Entertainment.Navigation Aid', 'kbb.Entertainment and Instrumentation.Navigation System'],
        'Cassette Player': ['faxvin.Entertainment.Cassette Player', 'kbb.Entertainment and Instrumentation.Cassette'],
    },
    Feature: {
        'Cruise Control': ['faxvin.Feature.Cruise Control', 'kbb.Comfort and Convenience.Cruise Control'],
        'Tilt Steering': ['faxvin.Feature.Tilt Steering', 'kbb.Steering.Tilt Wheel'],
        'Rear Spoiler': ['faxvin.Feature.Rear Spoiler', 'kbb.Exterior.Rear SpoilerRear Spoiler'],
        'Power Windows': ['faxvin.Feature.Power Windows', 'kbb.Comfort and Convenience.Power Windows'],
        'Running Boards': ['faxvin.Feature.Running Boards', 'kbb.Exterior.Running Boards'],
        'Power Sunroof': ['faxvin.Feature.Power Sunroof', 'kbb.Roof and Glass.Power Sun Roof'],
        'Sun Roof': ['kbb.Roof and Glass.Sun Roof (Flip-Up)', 'kbb.Roof and Glass.Sun Roof (Sliding)', 'faxvin.Feature.Manual Sunroof'],
        'Panorama Roof': ['kbb.Roof and Glass.Panorama Roof', 'kbb.Roof and Glass.Panoramic Sun Roof']
    },
    Colors: {
        'Interior Color': ['faxvin.Colors.Interior Trim'],
        'Exterior Color': ['faxvin.Colors.Exterior Color'],
    }
}

export function MapVinInfo(data) {
    const _data = _.cloneDeep(data);
    let _map = _.cloneDeep(map);

    const getVal = arr => {
        if (!arr || arr.length == 0) return arr;

        const set = new Set();

        arr.forEach(path => {
            const val = _.get(_data, path)

            if (val == 'Optional') {
                set.add('No');
                set.add('Yes');
            }
            else if (val == 'Standard') {
                set.add('Yes')
            }

            else if (val) {
                if (_.isArray(val))
                    val.forEach(item => set.add(item));
                else
                    set.add(val)
            }

            _.unset(_data, path);
        });

        return Array.from(set);
    }

    Object.keys(_map).forEach(group => {
        Object.keys(_map[group]).forEach(prop => {

            const value = getVal(_map[group][prop]);

            if (value.length > 0)
                _map[group][prop] = value;
            else delete _map[group][prop]
        })
    })

    function merge(...args) {
        return _.mergeWith({}, ...args, (a = { items: [] }, b = { items: [] }) => {
            a = a.items ? a.items : a;
            b = b.items ? b.items : b;

            const items = _.uniq([...(_.isArray(a) ? a : [a]), ..._.isArray(b) ? b : [b]]);
            return { items, selected: items && items[0], show: true };
        });
    }

    const { kbb = {}, faxvin = {}, adesa = {} } = _data;
    _map = {
        'Vehicle Data': merge(adesa.specs),
        'High Value': merge(adesa.highValue),
        Equipment: merge(adesa.equipment),
        Base: merge(kbb.main, faxvin.Basic, _map.Base),
        Drivetrain: merge(_map.Drivetrain, faxvin.Drivetrain),
        'Suspension And Steering': merge(faxvin.Suspension, kbb.Steering),
        Seating: merge(faxvin.Seating, kbb.Seats, _map.Seating),
        Fuel: merge(faxvin.Fuel, _map.Fuel),
        Braking: merge(faxvin.Braking, kbb['Braking and Traction'], _map.Braking),
        // 'Wheels and Tires': merge(faxvin['Wheels and Tires'], kbb['Wheels and Tires'], _map['Wheels and Tires']),
        Dimensions: merge(faxvin.Dimensions, _map.Dimensions),
        Weight: merge(faxvin.Weight, _map.Weight),
        Comfort: merge(faxvin.Comfort, kbb['Comfort and Convenience'], _map.Comfort),
        Safety: merge(faxvin.Safety, kbb['Safety and Security'], _map.Safety),
        Lighting: merge(faxvin.Lighting, kbb.Lighting, _map.Lighting),
        Entertainment: merge(faxvin.Entertainment, kbb['Entertainment and Instrumentation'], _map.Entertainment),
        Feature: merge(faxvin.Feature, kbb['Cargo and Towing'], kbb['Roof and Glass'], kbb.Exterior, _map.Feature),
        Colors: merge({ 'Exterior Color': 'No', 'Interior Color': 'No' }, faxvin.Colors, _map.Colors),
        Extras: []
    }

    //convert weights
    convertWeights(_map);
    convertDimension(_map);
    convertFuel(_map);

    return _map;
}
function convertWeights(_map) {
    Object.keys(_map.Weight).forEach(key => {
        if (_map.Weight[key].items.length == 0)
            return;

        _map.Weight[key].items = _map.Weight[key].items.map(weight => {
            if (weight && weight.trim().toLowerCase().endsWith('lbs')) {
                weight = parseInt(weight.replace('lbs', '').trim());
                weight *= 0.45359237;
                weight = weight.toFixed(1) + ' kg';
            }
            return weight;
        });

        _map.Weight[key].selected = _map.Weight[key].items[0];
    });
}

function convertDimension(_map) {
    Object.keys(_map.Dimensions).forEach(key => {
        if (_map.Dimensions[key].items.length == 0)
            return;

        _map.Dimensions[key].items = _map.Dimensions[key].items.map(Dimensions => {
            if (Dimensions && Dimensions.trim().toLowerCase().endsWith('in.')) {
                Dimensions = parseFloat(Dimensions.replace('in.', '').trim());
                Dimensions *= 0.0254;
                Dimensions = Dimensions.toFixed(2) + ' m';
            }
            return Dimensions
        });

        _map.Dimensions[key].selected = _map.Dimensions[key].items[0];
    });
}


function convertFuel(_map) {
    Object.keys(_map.Fuel).forEach(key => {
        if (_map.Fuel[key].items.length == 0)
            return;

        _map.Fuel[key].items = _map.Fuel[key].items.map(Fuel => {
            if (Fuel && ['Fuel Economy-city', 'Fuel Economy-highway'].includes(key)) {
                Fuel = parseFloat(Fuel.replace('miles/gallon', '').trim());
                Fuel = 235.21 / Fuel;
                Fuel = Fuel.toFixed(2) + ' litre/100km';
            }
            else if (Fuel && key == 'Tank') {
                Fuel = parseFloat(Fuel.replace('gallon', '').trim());
                Fuel *= 3.785412;
                Fuel = Fuel.toFixed(2) + ' litre';
            }

            return Fuel
        });

        _map.Fuel[key].selected = _map.Fuel[key].items[0];
    });
}