
import React, { useState, useEffect, useRef} from 'react';
import { db } from "./firebase";
import { Link } from "react-router-dom";

const ViewInquiries = () => {
  const [inquiries, setInquiries] = useState([]);

  useEffect(() => {    
    const unsubscribe = db.collection('inquiries').onSnapshot(snapshot => {
      const inquiries = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInquiries(inquiries);
    });

    return unsubscribe;
  }, []);

  function handleRemove(id) {
    db.collection('inquiries').doc(id).delete()
  }

  return (
    <>
    <section className="inventory-container">
      <div className="inventory-title">All Inquiries</div>
    </section>

    <section className="car-contact">
      <div className="inquiry-container book-appointment-container">
        {(inquiries && inquiries.length > 0) && inquiries.map((inquiry) => (
          <div className="vehicle-feature-spec inquiry">
            <Link to={`/warranty/${inquiry.id}/summary`}>{inquiry.id}</Link>
            <button style={{color:'red', cursor: 'pointer'}} onClick={() => handleRemove(inquiry.id)} variant="outline-danger">Remove</button>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default ViewInquiries;