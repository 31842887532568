import React, { useState } from "react";
import { auth } from "./firebase";
import Button from 'react-bootstrap/Button';
import { useNavigate, Link } from 'react-router-dom';

const SignIn = (props) => {
  const [errMessage, setErrMessage] = useState('');
  let navigate = useNavigate();

  const inputs = {
    email: "",
    password: "",
  }

  const [input, setInput] = useState(inputs);

  const updateField = (e) => {
    e.persist();
    setInput(prevInputs => ({...prevInputs, [e.target.name]: e.target.value }));
  }

  const signIn = async (e) => {
    e.preventDefault();
    try {
      await props.signIn(input.email, input.password);
    } catch(e){
      setErrMessage(e.message);
    }
    redirectToListing();
  }

  const redirectToListing = () => {
    navigate('/addListing');
  }

  const logout = () => {
    auth.signOut();
  }

  return (
    <section className="admin-login-container">
      {!props.currentUser ?
      <form>
        <div>
          <label className="form-label">Email address</label>
          <input type="email" className="form-control" name="email" placeholder="Enter email" value={input.email} onChange={updateField} required/>
        </div>
        <div>
          <label className="form-label">Password</label>
          <input type="password" className="form-control" name="password" placeholder="Password" value={input.password} onChange={updateField}/>
        </div>
        <Button type="submit" className="custom-outline-button show-result-fix" onClick={signIn}>Login</Button>
      </form>
      :
      <>
      <div className="admin-navigation-container">
        <Button className="custom-reset-button show-result-fix" style={{width: '100%'}}><Link to={`/add-by-vin`}>Add Listing By VIN</Link></Button>
        <Button className="custom-reset-button show-result-fix" style={{width: '100%'}}><Link to={`/addListing`}>Add Listing</Link></Button>
        <Button className="custom-button show-result-fix" style={{width: '100%'}}><Link to={`/view-appointments`}>View Appointments</Link></Button>
        <Button className="custom-reset-button show-result-fix" style={{width: '100%'}}><Link to={`/view-inquiries`}>View Inquiries</Link></Button>
        <Button className="custom-button show-result-fix" style={{width: '100%'}}><Link to={`/view-warranty-settings`}>Warranty Settings</Link></Button>
        <Button type="submit" className="custom-outline-button show-result-fix" onClick={logout}>logout</Button>
      </div>
      </>}
    </section>
  );
};

export default SignIn;