import { useParams } from "react-router-dom";
import Inventory from "./Inventory";
import CarDetail from "./CarDetail";

const CarContainer = ({ currentUser}) => {
    const { id } = useParams();
    console.log(id);
    return (
        <>
            <div className={id?"d-none":''}>
                <Inventory />

            </div>
            {id && <div>
                <CarDetail currentUser={currentUser} id={id}/>
            </div>
            }
        </>

    );
}

export default CarContainer;