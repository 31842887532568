import Pagination from 'react-bootstrap/Pagination';

import CarListing from './CarListing';
import { db } from "./firebase";
import React, { useState, useEffect, useRef } from "react";
import { FaThList } from "react-icons/fa";
import { BsGrid3X3GapFill } from "react-icons/bs";

const Inventory = () => {
  // const [defaultLoad, setDefaultLoad] = useState(200);
  const [sortOrder, setSortOrder] = useState('');

  const [inventory, setInventory] = useState([]);
  const [makeFilter, setMakeFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');
  const [maxPriceFilter, setMaxPriceFilter] = useState('');
  const [minYearFilter, setMinYearFilter] = useState('');
  const [maxKilometersFilter, setMaxKilometersFilter] = useState('');
  const [availabilityFilter, setAvailabilityFilter] = useState('');

  const [carType, setCarType] = useState('');

  const [filteredInventory, setFilteredInventory] = useState([]);
  const [sortedInventory, setSortedInventory] = useState([]);

  //These two are very important for the localstorage to work properly
  const effectCount = useRef(0);
  const initialLoad = useRef(true);

  const [gridView, setGridView] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12); // Change this number as needed


  //Get all cars stored on the firebase server and set it to the 'inventory' array
  useEffect(() => {
    const unsubscribe = db.collection('inventory').orderBy('status', 'asc').onSnapshot(snapshot => {
      const inventory = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInventory(inventory);
    });

    return unsubscribe;
  }, []);

  //Check the local storage and see if there are any values stored. If so, set them to the appropriate variables
  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("inventoryState"));

    if (savedState != null || savedState != undefined) {
      setMakeFilter(savedState.makeFilter);
      setMaxKilometersFilter(savedState.maxKilometersFilter);
      setMaxPriceFilter(savedState.maxPriceFilter);
      setMinYearFilter(savedState.minYearFilter);
      setModelFilter(savedState.modelFilter);
      setCarType(savedState.carType);
      setAvailabilityFilter(savedState.availabilityFilter);
    }

    handleFilter();
  }, [inventory && inventory.length > 0]);

  useEffect(() => {
    console.log(effectCount.current);
    effectCount.current++;
    if (effectCount.current > 3) {
      setMaxKilometersFilter('');
      setMaxPriceFilter('');
      setMinYearFilter('');
      setModelFilter('');
      setAvailabilityFilter('');
    } else if (effectCount.current > 1) {
      setCarType('');
    }
  }, [makeFilter]);

  useEffect(() => {
    handleFilter();
    // Check if it is past the initial load
    if (!initialLoad.current) {
      localStorage.setItem("inventoryState", JSON.stringify({ makeFilter, modelFilter, maxKilometersFilter, maxPriceFilter, minYearFilter, carType, availabilityFilter }));
    } else {
      // Set initial load to false after the first run
      initialLoad.current = false;
    }
    setCurrentPage(1);
  }, [makeFilter, modelFilter, maxPriceFilter, minYearFilter, maxKilometersFilter, availabilityFilter, carType]);

  //This code is fine for the most part
  const handleFilter = () => {
    if (carType !== '') {
      setFilteredInventory(inventory.filter((vehicle) => vehicle.bodyType.includes(carType)));
      // setCarType('');
    } else {
      const filteredInventory = inventory.filter((vehicle) =>
        vehicle.make.toLowerCase().includes(makeFilter.toLowerCase()) &&
        vehicle.model.toLowerCase().includes(modelFilter.toLowerCase()) &&
        (maxPriceFilter === '' || parseFloat(vehicle.askingPrice) <= parseFloat(maxPriceFilter)) &&
        (minYearFilter === '' || parseFloat(vehicle.year) >= parseInt(minYearFilter, 10)) &&
        (maxKilometersFilter === '' || parseFloat(vehicle.vehicleKilometers) <= parseFloat(maxKilometersFilter)) &&
        vehicle.status.includes(availabilityFilter)
      );
      setFilteredInventory(filteredInventory);
    }
    setSortOrder('');
  };

  useEffect(() => {
    handleSort();
  }, [sortOrder]);

  const sortingFunctions = {
    'recent': (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate),
    'status-desc': (a, b) => a.status.localeCompare(b.status),
    'price_desc': (a, b) => parseFloat(b.askingPrice) - parseFloat(a.askingPrice),
    'price_asc': (a, b) => parseFloat(a.askingPrice) - parseFloat(b.askingPrice),
    'odometer_desc': (a, b) => b.vehicleKilometers - a.vehicleKilometers,
    'odometer_asc': (a, b) => a.vehicleKilometers - b.vehicleKilometers,
    'year_desc': (a, b) => b.year - a.year,
    'year_asc': (a, b) => a.year - b.year,
    'make_asc': (a, b) => a.make.localeCompare(b.make),
    'make_desc': (a, b) => b.make.localeCompare(a.make),
  };

  const handleSort = () => {
    const sortingFunction = sortingFunctions[sortOrder] || (() => 0);
    const sortedInventory = [...filteredInventory].sort(sortingFunction);
    setSortedInventory(sortedInventory);
  };

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = [];

  if (sortOrder) {
    currentItems = sortedInventory.slice(indexOfFirstItem, indexOfLastItem);
  } else {
    currentItems = filteredInventory.sort((a, b) => sortingFunctions['status-desc'](a, b) || sortingFunctions['price_desc'](a, b)).slice(indexOfFirstItem, indexOfLastItem);
  }

  return (
    <section className="inventory-container">
      <div className="inventory-title">Inventory ({filteredInventory.length})</div>
      <div className="car-search-container">
        <div className="form-floating">
          <select aria-label="Floating label select example" className="form-select" value={makeFilter} onChange={e => setMakeFilter(e.target.value)}>
            <option value="">All Makes</option>
            {Object.keys(inventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.make.toUpperCase()]: (acc[vehicle.make] || 0) + 1 }), {}))
              .sort()
              .map((make, index) => (
                <option value={make} key={index}>{`${make} (${inventory.reduce((acc, vehicle) => (vehicle.make.toUpperCase() === make.toUpperCase() ? acc + 1 : acc), 0)})`}</option>
              ))}
          </select>
          <label>Make</label>
        </div>

        <div className="form-floating" >
          <select disabled={makeFilter !== "" ? false : true} aria-label="Floating label select example" className="form-select" value={modelFilter} onChange={e => setModelFilter(e.target.value)}>
            <option value="">All Models</option>
            {Object.keys(
              inventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.model]: (acc[vehicle.model] || 0) + 1 }), {})
            ).map((model, index) => {
              const count = inventory.reduce((acc, vehicle) => (vehicle.model === model && vehicle.make.toUpperCase() == makeFilter ? acc + 1 : acc), 0)
              return count > 0 ? <option value={model} key={index} > {`${model} (${count})`}</option> : <></>
            }
            )}

          </select>
          <label>Model</label>
        </div>

        <div className="form-floating">
          <input type="text" className="form-control" value={maxPriceFilter} onChange={(e) => setMaxPriceFilter(e.target.value)} />
          <label>Max Price ($)</label>
        </div>

        <div className="form-floating year-select">
          <select aria-label="Floating label select example" className="form-select" value={minYearFilter} onChange={e => setMinYearFilter(e.target.value)}>
            <option value="">All Years</option>
            {Object.keys(
              filteredInventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.year]: (acc[vehicle.year] || 0) + 1 }), {})
            ).map((year, index) => (
              <option value={year} key={index}>{`${year}`}</option>
            ))}
          </select>
          <label>Min Year</label>
        </div>

        <div className="form-floating">
          <input type="text" className="form-control" value={maxKilometersFilter} onChange={(e) => setMaxKilometersFilter(e.target.value)} />
          <label>Max Kilometers</label>
        </div>

        <div className="form-floating availability-select">
          <select aria-label="Floating label select example" className="form-select" value={availabilityFilter} onChange={e => setAvailabilityFilter(e.target.value)}>
            <option value="">All listings</option>
            <option value="Available">Available</option>
            <option value="Pending">Pending</option>
            <option value="Sold">Sold</option>
          </select>
          <label>Availability</label>
        </div>
      </div>

      {
        inventory && inventory.length > 0 ?
          <>
            <div className="inventory-results">
              <div className="result-header">
                <div className="results-showing">Showing <span className="result-num-bold">{currentItems.length}</span> of <span className="result-num-bold">{filteredInventory.length}</span> results</div>

                <div className="grid-and-sort-container">
                  <div role="group" className="grid-view-toggle btn-group">
                    <button type="button" className="btn btn-light" onClick={(e) => setGridView(false)}><BsGrid3X3GapFill size={25} color="#495057" /></button>
                    <button type="button" className="btn btn-light" onClick={(e) => setGridView(true)}><FaThList size={25} color="#495057" /></button>
                  </div>

                  <div className="results-sort">
                    <select className="form-control" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                      <option value="">Sort by: Default</option>
                      <option value="recent">Most Recent</option>
                      <option value="status-desc">Availability</option>
                      <option value="price_desc">Price: Highest</option>
                      <option value="price_asc">Price: Lowest</option>
                      <option value="odometer_desc">Kilometres: Highest</option>
                      <option value="odometer_asc">Kilometres: Lowest</option>
                      <option value="year_desc">Year: Newest</option>
                      <option value="year_asc">Year: Oldest</option>
                      <option value="make_asc">Make: A to Z</option>
                      <option value="make_desc">Make: Z to A</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <section className="inventory-cars">
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((vehicle) => (
                  <CarListing
                    vehicle={vehicle}
                    key={vehicle.id}
                    wideViewOnly={currentItems.length > 1 ? gridView : true}
                  ></CarListing>
                ))
              ) : (
                <div className="no-results-found">No results were found</div>
              )}

              {(sortOrder ? sortedInventory.length : filteredInventory.length) > itemsPerPage && (
                <Pagination className="pagination-inventory">
                  {Array.from({ length: Math.ceil((sortOrder ? sortedInventory.length : filteredInventory.length) / itemsPerPage) }, (_, i) => (
                    <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )}
            </section>
          </>
          :
          <div className="spinner-container">
            <div role="status" className="spinner-border spinner-styling">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }
    </section >
  );
}

export default Inventory;