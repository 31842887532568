import React from "react";
import { Link } from "react-router-dom";
import { db } from './firebase';

const Appointment = ({appointment}) => {
  function handleRemove(id) {
    db.collection('appointments').doc(id).delete()
  }

  return (
    <div className="appointment-option">
      <div className="">First Name: {appointment.firstName}</div>
      <div className="">Last Name: {appointment.lastName}</div>
      <div className="">Email Address: {appointment.emailAddress}</div>
      <div className="">Phone Number: {appointment.phoneNumber}</div>
      <div className="">Time Slot: {appointment.selectedTimeSlot}</div>
      <div className="">Upload Date: {appointment.date}</div>
      <div className="">Vehicle Description: {appointment.vehicleDescription}</div>

      <button><Link to={`edit/${appointment.id}`}>Edit</Link></button>
      <button style={{color:'red', cursor: 'pointer'}} onClick={() => handleRemove(appointment.id)} variant="outline-danger">Remove</button>
    </div>
  );
}

export default Appointment;