import React, { useState, useEffect, useRef} from 'react';
import { db } from "./firebase";
import Appointment from './Appointment';

const ViewAllAppointments = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {    
    const unsubscribe = db.collection('appointments').onSnapshot(snapshot => {
      const appointments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(appointments);
    });

    return unsubscribe;
  }, []);

	return (
<>
    <section className="inventory-container">
      <div className="inventory-title">Appointments</div>
    </section>

    <section className="car-contact">
      <div className="book-appointment-container">
        {appointments &&
          Object.entries(
            appointments
              .slice()
              .sort((a, b) => {
                // Sort by date and then by selected time slot
                const dateComparison = new Date(a.date) - new Date(b.date);
                if (dateComparison === 0) {
                  return a.selectedTimeSlot.localeCompare(b.selectedTimeSlot);
                }
                return dateComparison;
              })
              .reduce((acc, appointment) => {
                const currentDate = new Date(appointment.date).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                });

                if (!acc[currentDate]) {
                  acc[currentDate] = [];
                }
                acc[currentDate].push(appointment);
                return acc;
              }, {})
          )
          .map(([date, appointmentsForDate]) => (
            <div key={date} className="car-contact">
              <div className="appointment-date-subheader">
                <h4>{date}</h4>
              </div>
              {/* Sort appointments for each date by selected time slot */}
              {appointmentsForDate.sort((a, b) => a.selectedTimeSlot.localeCompare(b.selectedTimeSlot)).map((appointment, index) => (
                <Appointment appointment={appointment} key={index}></Appointment>
              ))}
            </div>
          ))
        }
      </div>
    </section>
  </>
	);
}
  
export default ViewAllAppointments;
