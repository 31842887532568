import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { db } from "./firebase";

const StepTwo = () => {
  const [warranties, setWarranties] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [eligiblePrograms, setEligiblePrograms] = useState(['1 Year', '2 Year', '3 Year', '4 Year']);
  const [inquiry, setInquiry] = useState();
  
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    db.collection('inquiries').doc(id).onSnapshot(doc => {
      setInquiry(doc.data());

      if (doc.data() && doc.data().planType !== undefined) {
        setSelectedOption(doc.data().planType);
      }

      if (doc.data() && doc.data().vehicleInformation !== undefined) {
        checkEligibility(doc.data().vehicleInformation.odometerReading);
      } 
    })
  }, []);

  useEffect(() => {
    const unsubscribe = db.collection('Warranty').onSnapshot(snapshot => {
      const warranties = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setWarranties(warranties);
    });

    return unsubscribe;
  }, []);

  // Separate warranties by plan type
  const groupedWarranties = {
    Platinum: [],
    Titanium: [],
    Gold: [],
    Silver: [],
    Bronze: [],
  };

  warranties.forEach(warranty => {
    const { planType } = warranty;
    groupedWarranties[planType].push(warranty);
  });

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const nextStep = (e) => {
    e.preventDefault();
    db.collection('inquiries').doc(id).update({
      planType: selectedOption
    })
    navigate(`/warranty/${id}/customer`);
  };

  const previousStep = (e) => {
    e.preventDefault();
    db.collection('inquiries').doc(id).update({
      planType: selectedOption
    })
    navigate(`/warranty/${id}/vehicle`);
  };

  // Sort warranties by year and mileage for each plan type in reverse order
  Object.keys(groupedWarranties).forEach(planType => {
    groupedWarranties[planType].sort((a, b) => {
      const yearOrder = {
        '3 Month': 1,
        '6 Month': 2,
        '1 Year': 3,
        '2 Year': 4,
        '3 Year': 5,
        '4 Year': 6,
      };

      const mileageOrder = {
        '20,000 KMs': 1,
        '40,000 KMs': 2,
        '60,000 KMs': 3,
        '80,000 KMs': 4,
        'Unlimited': 5
      };

      const maxClaimOrder = {
        '$1,000': 1,
        '$2,000': 2,
        '$3,000': 3,
        '$4,000': 4,
        '$5,000': 5,
        '$10,000': 6,
        '$25,000': 7,
        'Retail': 8
      };

      // Compare by year
      if (a.duration !== b.duration) {
        return yearOrder[a.duration] - yearOrder[b.duration];
      }

      // Compare by mileage
      if (a.mileage !== b.mileage) {
        return mileageOrder[a.mileage] - mileageOrder[b.mileage];
      }

      // Compare by maxClaim
      return maxClaimOrder[a.maxClaim] - maxClaimOrder[b.maxClaim];
    });
  });

  const checkEligibility = (mileage) => {
    if (mileage <= 120000) {
      return eligiblePrograms;
    } else if (mileage <= 160000) {
      return setEligiblePrograms(['1 Year', '2 Year']);
    } else {
      return setEligiblePrograms([]);
    }
  };

  return (
    <>
      <div className="warranty-progress-tracker">
        <div>VEHICLE INFORMATION</div>
        <div>/</div>
        <div className="warranty-progress-selected">PROGRAMS</div>
        <div>/</div>
        <div>CUSTOMER INFORMATION</div>
        <div>/</div>
        <div>SUMMARY</div>
      </div>      

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{backgroundColor: 'var(--platinum)'}}>PLATINUM</Accordion.Header>
          <Accordion.Body>
            <div className="coverage-container" style={{gap: '0px'}}>
              <div className="coverage-header">
                <p>The ULTIMATE comprehensive warranty. Simply put, the best program to give your customers the ULTIMATE peace of mind.</p>
                <p>Platinum covers all of Bronze, Silver, Gold, and Titanium level coverage PLUS ALL parts and components, just like OEM new car warranty. It even includes all diesel parts and super hi-tech/luxury items.</p>
                <h6>Vehicle Eligibility:</h6>
                <ul>
                  <li>Vehicles with an odometer <b>up to 160,000 KMs are eligible for 1 and 2 year terms</b></li>
                  <li>Vehicles with an odometer <b>up to 120,000 KMs are eligible for 3 and 4 year terms</b></li>
                  <li>Vehicles with an odometer <b>over 160,000 KMs are not eligible</b></li>
                </ul>
              </div>

              <Accordion defaultActiveKey="1 Year">
                 {eligiblePrograms.map(year => (
                  <Accordion.Item eventKey={year} key={year}>
                    <Accordion.Header style={{backgroundColor: 'var(--platinum)'}}>{year}</Accordion.Header>
                    <Accordion.Body>
                      <Form>
                        {['Platinum'].map(planType => (
                          <div key={planType}>
                            {groupedWarranties[planType].filter(warranty => warranty.duration === year).map(warranty => (
                              <Form.Check
                                type="radio"
                                label={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                value={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                checked={selectedOption === `${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                onChange={handleChange}
                                key={warranty.id}
                              />
                            ))}
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header style={{backgroundColor: 'var(--titanium)'}}>TITANIUM</Accordion.Header>
          <Accordion.Body>
            <div className="coverage-container" style={{gap: '0px'}}>
              <div className="coverage-header">
                <p>Our most popular comprehensive warranty. With today's vehicle systems changing, our Titanium coverage is designed to protect your ever-evolving inventory.</p>
                <p>Titanium includes Bronze, Silver and Gold coverage PLUS heating & cooling, suspension systems, electronic modules & controls, sensors & switches, power accessories, emissions, electronic hi-tech, advanced drivers assistance systems, air bags, and hybrid/electric.</p>
                <h6>Vehicle Eligibility:</h6>
                <ul>
                  <li>Vehicles with an odometer <b>up to 160,000 KMs are eligible for 1 and 2 year terms</b></li>
                  <li>Vehicles with an odometer <b>up to 120,000 KMs are eligible for 3 and 4 year terms</b></li>
                  <li>Vehicles with an odometer <b>over 160,000 KMs are not eligible</b></li>
                </ul>
              </div>
              <Accordion defaultActiveKey="1 Year">
                 {eligiblePrograms.map(year => (
                  <Accordion.Item eventKey={year} key={year}>
                    <Accordion.Header style={{backgroundColor: 'var(--titanium)'}}>{year}</Accordion.Header>
                    <Accordion.Body>
                      <Form>
                        {['Titanium'].map(planType => (
                          <div key={planType}>
                            {groupedWarranties[planType].filter(warranty => warranty.duration === year).map(warranty => (
                              <Form.Check
                                type="radio"
                                label={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                value={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                checked={selectedOption === `${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                                onChange={handleChange}
                                key={warranty.id}
                              />
                            ))}
                          </div>
                        ))}
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header style={{backgroundColor: 'var(--gold)'}}>GOLD</Accordion.Header>
          <Accordion.Body>
            <div className="coverage-container" style={{gap: '0px'}}>
              <div className="coverage-header">
                <p>It's your Golden Opportunity. Give your customer a step up in peace of mind coverage.</p>
                <p>Gold includes all of Silver level coverage PLUS air conditioning, fuel & injection systems (gas only), and steering systems.</p>
                <h6>Vehicle Eligibility:</h6>
                <ul>
                  <li>Vehicles with an odometer <b>up to 160,000 KMs are eligible for 1 and 2 year terms</b></li>
                  <li>Vehicles with an odometer <b>up to 120,000 KMs are eligible for 3 and 4 year terms</b></li>
                  <li>Vehicles with an odometer <b>over 160,000 KMs are not eligible</b></li>
                </ul>
              </div>
              <Accordion defaultActiveKey="1 Year">
                {eligiblePrograms.map(year => (
                <Accordion.Item eventKey={year} key={year}>
                  <Accordion.Header style={{backgroundColor: 'var(--gold)'}}>{year}</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      {['Gold'].map(planType => (
                        <div key={planType}>
                          {groupedWarranties[planType].filter(warranty => warranty.duration === year).map(warranty => (
                            <Form.Check
                              type="radio"
                              label={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              value={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              checked={selectedOption === `${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              onChange={handleChange}
                              key={warranty.id}
                            />
                          ))}
                        </div>
                      ))}
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header style={{backgroundColor: 'var(--silver)'}}>SILVER</Accordion.Header>
          <Accordion.Body>
            <div className="coverage-container" style={{gap: '0px'}}>
              <div className="coverage-header">
                <p>Powertrain Plus. Silver is designed to give your customers more than just the powertrain protection and greater flexibility to meet their driving needs.</p>
                <p>Silver coverage includes all of the Bronze level PLUS diagnostics, driveline, braking system and electrical system. Combine with our other flexible coverage options: HYBRID/ELECTRIC, DIESEL COMPONENTS, ELECTRONIC HI-TECH, and AIR CONDITIONING.</p>
                <h6>Vehicle Eligibility:</h6>
                <p>Eligible on Any Year, Any Make, Any Model and up to 1,000,000 KMs.</p>
              </div>
              <Accordion defaultActiveKey="1 Year">
                {['1 Year', '2 Year', '3 Year', '4 Year'].map(year => (
                <Accordion.Item eventKey={year} key={year}>
                  <Accordion.Header style={{backgroundColor: 'var(--silver)'}}>{year}</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      {['Silver'].map(planType => (
                        <div key={planType}>
                          {groupedWarranties[planType].filter(warranty => warranty.duration === year).map(warranty => (
                            <Form.Check
                              type="radio"
                              label={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              value={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              checked={selectedOption === `${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              onChange={handleChange}
                              key={warranty.id}
                            />
                          ))}
                        </div>
                      ))}
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        
        <Accordion.Item eventKey="4">
          <Accordion.Header style={{backgroundColor: 'var(--bronze)'}}>BRONZE</Accordion.Header>
          <Accordion.Body>
            <div className="coverage-container" style={{gap: '0px'}}>
              <div className="coverage-header">
                <p>The industry's best powertrain coverage and a whole lot more. Bronze fits any vehicle in your inventory.</p>
                <p>Bronze includes coverage on complete powertrain parts and components including seals & gaskets, wear & tear, and car rental. Most popular coverage for used vehicles due to the increased probability of breakdown. Commonly combined with our ULTIMATE Test Drive option to give your customers 60 days/4,000 kms to exchange the vehicle at your Dealership. Ensure your customers walk away satisfied and with peace of mind.</p>
                <h6>Vehicle Eligibility:</h6>
                <p>Eligible on Any Year, Any Make, Any Model and up to 1,000,000 KMs.</p>
              </div>
              <Accordion defaultActiveKey="3 Month">
                {['3 Month', '6 Month', '1 Year', '2 Year', '3 Year', '4 Year'].map(year => (
                <Accordion.Item eventKey={year} key={year}>
                  <Accordion.Header style={{backgroundColor: 'var(--bronze)'}}>{year}</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      {['Bronze'].map(planType => (
                        <div key={planType}>
                          {groupedWarranties[planType].filter(warranty => warranty.duration === year).map(warranty => (
                            <Form.Check
                              type="radio"
                              label={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              value={`${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              checked={selectedOption === `${warranty.planType} / ${warranty.duration} / ${warranty.mileage} / ${warranty.maxClaim} / $${warranty.price}`}
                              onChange={handleChange}
                              key={warranty.id}
                            />
                          ))}
                        </div>
                      ))}
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <form style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div className="custom-warranty-button-container">
          <Button className="custom-next-button financing-button" onClick={(e) => previousStep(e)}>Back</Button>              
          <Button className="custom-next-button financing-button" disabled={selectedOption.length > 0 ? false : true} onClick={(e) => nextStep(e)}>Continue</Button>
        </div>
      </form>
    </>
  );
};

export default StepTwo;