import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
// import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { BsXSquareFill } from "react-icons/bs";

const EditWarranty = () => {
  const { id } = useParams();
  const [warrantyOption, setWarrantyOption] = useState();

  useEffect(() => {
    db.collection('Warranty').doc(id).onSnapshot(doc => {
      setWarrantyOption(doc.data());
    })
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    db.collection('Warranty').doc(id).update({
      planType: warrantyOption.planType,
      duration: warrantyOption.duration,
      mileage: warrantyOption.mileage,
      maxClaim: warrantyOption.maxClaim,
      price: warrantyOption.price,
    })
  }
      
  const updateField = (e) => {
    e.persist();
    setWarrantyOption(prevInputs => ({ ...prevInputs, [e.target.name]: e.target.value }));
  }
  
  return (
    <section className="inventory-container">
      {warrantyOption && 
        <div className="">
          <form onSubmit={handleSubmit}>            
            <label className="mb-2">Plan Type:</label>
            <div className="mb-3" required>
              <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" name="planType" value={warrantyOption.planType} onChange={updateField}>
                <option value="" disabled>Select a plan</option>
                <option value="Platinum">Platinum</option>
                <option value="Titanium">Titanium</option>
                <option value="Gold">Gold</option>
                <option value="Silver">Silver</option>
                <option value="Bronze">Bronze</option>
              </select>
            </div>

            <label className="mb-2">Duration:</label>
            <div className="mb-3" required>
              <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" name="duration" value={warrantyOption.duration} onChange={updateField}>
                <option value="" disabled>Select a duration</option>
                <option value="3 Month">3 Month</option>
                <option value="6 Month">6 Month</option>
                <option value="1 Year">1 Year</option>
                <option value="2 Year">2 Years</option>
                <option value="3 Year">3 Years</option>
                <option value="4 Year">4 Years</option>
              </select>
            </div>

            <label className="mb-2">Mileage:</label>
            <div className="mb-3" required>
              <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" name="mileage" value={warrantyOption.mileage} onChange={updateField}>
                <option value="" disabled>Select a mileage</option>
                <option value="20,000 KMs">20,000 KM</option>
                <option value="40,000 KMs">40,000 KM</option>
                <option value="60,000 KMs">60,000 KM</option>
                <option value="80,000 KMs">80,000 KM</option>
                <option value="Unlimited">Unlimited</option>
              </select>
            </div>

            <label className="mb-2">Max Claim:</label>
            <div className="mb-3" required>
              <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" name="maxClaim" value={warrantyOption.maxClaim} onChange={updateField}>
                <option value="" disabled>Select a mileage</option>
                <option value="1000">$1,000</option>
                <option value="2000">$2,000</option>
                <option value="3000">$3,000</option>
                <option value="3000">$4,000</option>
                <option value="5000">$5,000</option>
                <option value="10000">$10,000</option>
                <option value="25000">$25,000</option>
                <option value="Retail">Retail</option>
              </select>
            </div>
              
            <label className="mb-2">Price:</label>
            <input type="number" className="form-control mb-3" placeholder="" value={warrantyOption.price}  name="price" onChange={updateField} />

            <label className="mb-2">Upload Date:</label>
            <input type="text" className="form-control mb-3" disabled placeholder="" name="uploadDate" value={warrantyOption.uploadDate}/>

            <Button type="submit" className="btn btn-primary">Update</Button>
          </form>
        </div>
      }
    </section>
  );
}

export default EditWarranty;