import Button from 'react-bootstrap/Button';

const Error = () => {
    return (
      <div className="error-container">
        <div className="error-code">404</div>
        <div className="error-message">PAGE NOT FOUND</div>
        <div className="error-description">Sorry, the page you're looking for doesn't exist. </div>
        <Button className="custom-button show-result-fix" href="/">Return home</Button>
      </div>
    );
  }
  
  export default Error;