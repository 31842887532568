import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

const FinanceCalculator = ({priceOfCar}) => {
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  // document.body.scrollTop = 0; // For Safari

  //!ASK THEM ABOUT THE SALES TAX PERCENTAGE AND LICENSING FEES
  const [customPrice, setCustomPrice] = useState(0); 
  const [taxPercentage, setTaxPercentage] = useState(12);
  const [licensingFee, setLicensingFee] = useState(399);
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // const [carPrice, setCarPrice] = useState(15000);
  const [downPayment, setDownPayment] = useState(0); 
  const [tradeInValue, setTradeInValue] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [interestRate, setInterestRate] = useState(5.99);
  const [financeTerm, setFinanceTerm] = useState(60); 
  const [paymentFrequency, setPaymentFrequency] = useState('bi-weekly');

  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalObligation, setTotalObligation] = useState(0);
  const [costOfBorrowing, setCostOfBorrowing] = useState(0);

  useEffect(() => {
    setCustomPrice(priceOfCar);
    if (priceOfCar > 0) {
      calculateMonthlyPayment();
    }
  }, [priceOfCar]);

  // Add your calculation logic here
  const calculateMonthlyPayment = (e) => {
    let price = customPrice > 0 ? customPrice : priceOfCar;
    const tempSalesTax = (price * (taxPercentage / 100));

    const P = (price + tempSalesTax + licensingFee) - (downPayment + tradeInValue);
    const r = interestRate / 12;
    const n = financeTerm; 

    //Calculating the monthly payment
    let numerator = (r/100) * ((1 + (r/100)) ** n);
    let denominator =((1 + (r/100))**n) - 1;

    const tempMonthlyPayment = (P * (numerator/denominator));
    const tempCostOfBorrowing = ((tempMonthlyPayment * financeTerm) - P);
    const tempTotalObligation = (price + tempCostOfBorrowing + tempSalesTax);
    
    setCostOfBorrowing(tempCostOfBorrowing.toFixed(2));
    setSalesTax(tempSalesTax.toFixed(2));
    setTotalObligation(tempTotalObligation.toFixed(2));

    setMonthlyPayment(tempMonthlyPayment.toFixed(0));
  }

  return (
    <div className="product-financing-calculator">
      <div className="vehicle-title">Estimate Payments</div>
      <div className="financing-payment-title">Payment Configuration</div>
      <div className="form-group">
        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={`${customPrice}`}
            onChange={(e) => setCustomPrice(parseFloat(e.target.value))}
          />
          <label>Asking Price ($)</label>
        </div>

        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={licensingFee}
            onChange={(e) => setLicensingFee(parseFloat(e.target.value))}
            disabled
          />
          <label>Financing Fee ($)</label>
        </div>

        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={taxPercentage}
            onChange={(e) => setTaxPercentage(parseFloat(e.target.value))}
            disabled
          />
          <label>Sales Tax (%)</label>
        </div>
      </div>
      <div className="form-floating">
        <select
          aria-label="Payment Frequency"
          className="form-select"
          value={paymentFrequency}
          onChange={(e) => setPaymentFrequency(e.target.value)}
        >
          <option value='monthly'>Monthly</option>
          <option value='bi-weekly'>Bi-Weekly</option>
        </select>
        <label>Payment Frequency</label>
      </div>

      <div className="form-floating">
        <select
          aria-label="Finance Term & APR"
          className="form-select"
          value={financeTerm}
          onChange={(e) => setFinanceTerm(e.target.value)}
        >
          <option value={60}>5 years</option>
          <option value={48}>4 years</option>
          <option value={36}>3 years</option>
          <option value={24}>2 years</option>
          <option value={12}>1 year</option>
          <option value={6}>6 months</option>
        </select>
        <label>Finance Term & APR</label>
      </div>

      <div className="form-group">
        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={`${interestRate}`}
            onChange={(e) => setInterestRate(parseFloat(e.target.value))}
          />
          <label>Interest Rate (%)</label>
        </div>

        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={downPayment}
            onChange={(e) => setDownPayment(parseFloat(e.target.value))}
          />
          <label>Down Payment</label>
        </div>

        <div className="form-floating form-child">
          <input
            type="number"
            className="form-control"
            value={tradeInValue}
            onChange={(e) => setTradeInValue(parseFloat(e.target.value))}
          />
          <label>Trade-in value</label>
        </div>
      </div>

      <div className="financing-payment-title">Pricing Breakdown</div>
      <div className="dealer-price">
        <div>Dealer price</div>
        <div>${parseFloat(customPrice).toLocaleString()}</div>
      </div>

      <div className="dealer-price">
        <div>Financing Fee</div>
        <div>${parseFloat(licensingFee).toLocaleString()}</div>
      </div>

      <div className="cost-of-borrowing">
        <div>Cost of borrowing</div>
        <div>${parseFloat(costOfBorrowing).toLocaleString()}</div>
      </div>

      <div className="sales-tax">
        <div>Sales Tax (12%)</div>
        <div>${parseFloat(salesTax).toLocaleString()}</div>
      </div>

      <div className="total-obligation">
        <div>Total obligation</div>
        <div>${parseFloat(totalObligation).toLocaleString()}</div>
      </div>

      <div className="estimated-payment">
        <div>Your estimated payment</div>
        <div>${paymentFrequency == 'monthly' ? parseFloat(monthlyPayment).toLocaleString() : (parseFloat(monthlyPayment) / 2).toLocaleString()} {paymentFrequency}*</div>
      </div>
      
      <Button className="custom-reset-button financing-button" onClick={e => calculateMonthlyPayment()}>Calculate Payments</Button>
      <div className="financing-disclaimer">
        *Interest rates displayed are for demonstrational/comparison purposes only and may differ depending on creditworthiness and financing offers available from dealers and/or third party lender. Please confirm accuracy of information with seller. Mileage recorded at time of listing. Taxes are not included in the listing price.
      </div>
    </div>
  );
}

export default FinanceCalculator;