import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <ul className="footer justify-content-center border-bottom pb-3 mb-3">
        <li className="footer-item"><Link to={`/`}>Home</Link></li>
        <li className="footer-item"><Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: ''}))}>Inventory</Link></li>
        <li className="footer-item"><Link to={`/about`}>About us</Link></li>
        <li className="footer-item"><Link to={`/financing`}>Financing</Link></li>
        <li className="footer-item"><Link to={`/appointment`}>Book Appointment</Link></li>
        <li className="footer-item"><Link to={`/contact`}>Contact us</Link></li>
      </ul>
      <p className="text-center text-muted">© 2023 Serene Auto | Luma Marketing Agency</p>
    </footer>
  );
}

export default Footer;