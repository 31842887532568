import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from "./firebase";

const StepThree = () => {
  const [customerInformation, setCustomerInformation] = useState({});
  const [inquiry, setInquiry] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    db.collection('inquiries').doc(id).onSnapshot(doc => {
      setInquiry(doc.data());

      if(doc.data() && doc.data().customerInformation !== undefined) {
        setCustomerInformation(doc.data().customerInformation);
      }
    })
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCustomerInformation((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
  };

  // const nextStep = (e) => {
  //   e.preventDefault();
  //   db.collection('inquiries').doc(id).update({
  //     customerInformation: customerInformation
  //   })
  //   navigate(`/warranty/${id}/summary`);
  // };

  const previousStep = (e) => {
    e.preventDefault();
    db.collection('inquiries').doc(id).update({
      customerInformation: customerInformation
    })
    navigate(`/warranty/${id}/programs`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection('inquiries').doc(id).update({
      customerInformation: customerInformation
    })
    navigate(`/warranty/${id}/summary`);
  };

  return (
    <>
      <div className="warranty-progress-tracker">
        <div>VEHICLE INFORMATION</div>
        <div>/</div>
        <div>PROGRAMS</div>
        <div>/</div>
        <div className="warranty-progress-selected">CUSTOMER INFORMATION</div>
        <div>/</div>
        <div>SUMMARY</div>
      </div>  

      <form onSubmit={handleSubmit} className="warranty-form" style={{paddingBottom: '200px'}}>
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={customerInformation.firstName}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>First Name</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="inital"
              value={customerInformation.inital}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Inital</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={customerInformation.lastName}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Last Name</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="mailingAddress"
              value={customerInformation.mailingAddress}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Mailing Address</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="apartmentNumber"
              value={customerInformation.apartmentNumber}
              onChange={handleChange}
              placeholder=" "
            />
            <label>Apartment Number</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="postalCode"
              value={customerInformation.postalCode}
              onChange={handleChange}
              placeholder=""
              required
            />
            <label>Postal Code</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="city"
              value={customerInformation.city}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>City</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="province"
              value={customerInformation.province}
              onChange={handleChange}
              placeholder=""
              required
            />
            <label>Province</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              value={customerInformation.phoneNumber}
              onChange={handleChange}
              placeholder="(204) 123-1234"
              required
            />
            <label>Phone Number</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="email"
              className="form-control"
              name="emailAddress"
              value={customerInformation.emailAddress}
              onChange={handleChange}
              placeholder=" "
              required
            />
            <label>Email Address</label>
          </div>
        </div>
        
        <div className="custom-warranty-button-container">
          <Button className="custom-next-button financing-button" onClick={(e) => previousStep(e)}>Back</Button>              
          <Button className="custom-next-button financing-button" type="submit">Continue</Button>
        </div>
      </form>
    </>
  );
};

export default StepThree;
