import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import moment from 'moment';
import Button from 'react-bootstrap/Button';

const AddWarranty = () => {
  const [planType, setPlanType] = useState(''); //Represents the type of plan, such as "Platinum".
  const [duration, setDuration] = useState(''); //Indicates the duration of coverage, for example, "1 Year".
  const [mileage, setMileage] = useState(''); //Refers to the mileage limit, like "20,000 KMS".
  const [maxClaim, setMaxClaim] = useState(''); //Denotes the maximum claimable amount, e.g., "$5,000 Max Per Claim".
  const [price, setPrice] = useState(''); //Represents the price of this specific plan, such as "$1,319.00". 
  const [uploadDate, setUploadDate] = useState(moment().format('LLL'));

  // const warranties = [
   
  // ];

  // const addWarrantyToDB = async (warranty) => {
  //   const warrantyRef = db.collection('Warranty');
  //   const snapshot = await warrantyRef
  //     .where('planType', '==', warranty.planType)
  //     .where('duration', '==', warranty.duration)
  //     .where('mileage', '==', warranty.mileage)
  //     .where('maxClaim', '==', warranty.maxClaim)
  //     .where('price', '==', warranty.price)
  //     .get();

  //   if (snapshot.empty) {
  //     await warrantyRef.add({
  //       planType: warranty.planType,
  //       duration: warranty.duration,
  //       mileage: warranty.mileage,
  //       maxClaim: warranty.maxClaim,
  //       price: warranty.price,
  //       uploadDate: moment().format('LLL'),
  //     });
  //     console.log('Warranty added to database:', warranty);
  //   } else {
  //     console.log('Warranty already exists in the database:', warranty);
  //   }
  // };

  // // useEffect to automatically add warranties to the database when component mounts
  // useEffect(() => {
  //   warranties.forEach(warranty => {
  //     addWarrantyToDB(warranty);
  //   });
  // }, []);

  const handleSubmit = e => {    
    e.preventDefault();
    db.collection('Warranty').add({
      planType,
      duration,
      mileage,
      maxClaim,
      price,
      uploadDate, 
    });

    setPlanType('');
    setDuration('');
    setMileage('');
    setMaxClaim('');
    setPrice('');
    setUploadDate('');
  }
  
  return (
    <div className="inventory-container">
      <form onSubmit={handleSubmit}>
        <label className="mb-2">Plan Type:</label>
        <div className="mb-3" required>
          <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" value={planType} onChange={e => setPlanType(e.target.value)}>
            <option value="" disabled>Select a plan</option>
            <option value="Platinum">Platinum</option>
            <option value="Titanium">Titanium</option>
            <option value="Gold">Gold</option>
            <option value="Silver">Silver</option>
            <option value="Bronze">Bronze</option>
          </select>
        </div>

        <label className="mb-2">Duration:</label>
        <div className="mb-3" required>
          <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" value={duration} onChange={e => setDuration(e.target.value)}>
            <option value="" disabled>Select a duration</option>
            <option value="3 Month">3 Month</option>
            <option value="6 Month">6 Month</option>
            <option value="1 Year">1 Year</option>
            <option value="2 Year">2 Years</option>
            <option value="3 Year">3 Years</option>
            <option value="4 Year">4 Years</option>
          </select>
        </div>

        <label className="mb-2">Mileage:</label>
        <div className="mb-3" required>
          <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" value={mileage} onChange={e => setMileage(e.target.value)}>
            <option value="" disabled>Select a mileage</option>
            <option value="20,000 KMs">20,000 KM</option>
            <option value="40,000 KMs">40,000 KM</option>
            <option value="60,000 KMs">60,000 KM</option>
            <option value="80,000 KMs">80,000 KM</option>
            <option value="Unlimited">Unlimited</option>
          </select>
        </div>

        <label className="mb-2">Max Claim:</label>
        <div className="mb-3" required>
          <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" value={maxClaim} onChange={e => setMaxClaim(e.target.value)}>
            <option value="" disabled>Select a mileage</option>
            <option value="1000">$1,000</option>
            <option value="2000">$2,000</option>
            <option value="3000">$3,000</option>
            <option value="3000">$4,000</option>
            <option value="5000">$5,000</option>
            <option value="10000">$10,000</option>
            <option value="25000">$25,000</option>
            <option value="Retail">Retail</option>
          </select>
        </div>
          
        <label className="mb-2">Price:</label>
        <input type="number" className="form-control mb-3" placeholder="" value={price} onChange={e => setPrice(e.target.value)} required />

        <label className="mb-2">Upload Date:</label>
        <input type="text" className="form-control mb-3" disabled placeholder="" value={uploadDate} onChange={e => setUploadDate(e.target.value)} required/>

        <Button type="submit" className="btn btn-primary">Add Warranty</Button>
      </form>
    </div>
  );
}

export default AddWarranty;