import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const ContactForm = () => {
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rv3azso",
        "template_n6l2zip",
        form.current,
        "20_Hw3moJZpd6weBw"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setShowSuccessMessage(true); // Set state to display success message
          setTimeout(() => {
            setShowSuccessMessage(false); // Hide success message after some time
          }, 3000); // Adjust the time as needed (in milliseconds)
        },
        (error) => {
          console.log(error.text);
        }
      );

    document.getElementById("myForm").reset();
  }

  return (
    <div className="car-contact-form">
      <div className="car-contact-title">Need to get in touch with us?</div>
      <p className="contact-description">Either fill out the form with your inquiry or find the department email you'd like to contact below.</p>
  
      <form ref={form} onSubmit={sendEmail} id="myForm">
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-child">
            <label className="form-label">First Name</label>
            <input type="text" className="form-control mb-3" name="from_firstName" placeholder="" required/>
          </div>
    
          <div className="form-child">
            <label className="form-label">Last Name</label>
            <input type="text" className="form-control mb-3" name="from_firstName" placeholder="" required/>
          </div>
        </div> 
    
        <label className="mb-2">Vehicle Description</label>
        <input type="text" className="form-control mb-3" name="from_vehicle_description" placeholder="Toyota Camry 2020 Black" required/>
    
        <label className="mb-2">Email Address</label>
        <input type="email" className="form-control mb-3" name="from_email" placeholder="" required/>
    
        <label className="mb-2">Phone Number</label> 
        <input type="text" className="form-control mb-3" name="from_phoneNumber" placeholder="" required/>
        
        <label className="mb-2">Message</label>
        <textarea type="text" className="form-control mb-3" name="message" rows="3" maxLength="800" required />
      
        <Button type="submit"  value="Send" className="custom-button show-result-fix">SEND MESSAGE</Button>
      </form>

      {showSuccessMessage && (
        <Alert variant='success'>Your message was sent successfully!</Alert>
      )}
    </div>
  );
}
  
export default ContactForm;