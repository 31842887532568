import React from "react";
import ContactForm from './ContactForm';

const ContactPage = () => {
	return (
    <>
      <section className="inventory-container">
        <div className="inventory-title">Contact Us</div>
      </section>

      <section className="car-contact">
        <div className="car-contact-container">
          <div className="car-contact-information">
            <div className="car-dealership">
              <div className="car-dealership-title dark">Serene Auto</div>
              <div className="car-dealership-info dark">3370 Wilkes Ave, Winnipeg, MB R3S 1A8</div>
              <div className="car-dealership-info dark">+1 (431) 588-4511</div>
              <div className="car-dealership-info dark">Info@sereneauto.ca</div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d329346.8510603042!2d-97.258968!3d49.842039!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea0b232351466b%3A0xb296177e8d08fad6!2s3370%20Wilkes%20Ave%2C%20Winnipeg%2C%20MB%20R3S%201A8%2C%20Canada!5e0!3m2!1sen!2sus!4v1694051050273!5m2!1sen!2sus" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <ContactForm/>
        </div>
      </section>
    </>
	);
}
  
  export default ContactPage;