import _, { isEmpty } from "lodash";
import { MapVinInfo } from "./FeatureMap"
import { useEffect, useState } from "react";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import { MdEdit, MdOutlineDoneOutline } from "react-icons/md";
import AdPaths from './AdPaths.json';
import { getTagValue } from "./AdTemplate";
import { IconButton, MenuItem, TextField } from "@mui/material";
import { FiSave } from "react-icons/fi";
import { vehicleDataProps } from "./feature-enums";
const groups = [
    'Base',
    'Entertainment',
    'Feature',
    'Fuel',
    'Comfort',
    'Drivetrain',
    'Suspension And Steering',
    'Seating',
    'Braking',
    'Dimensions',
    'Weight',
    'Safety',
    'Lighting',
    'Colors'
];
const SpecSheet = ({ vin, editMode, onSubmit, data, collapsed = false, onChange }) => {
    const [features, setFeatures] = useState(data)
    const [edit, setEdit] = useState('');
    const [adSet, setAdvertiseSet] = useState(new Set())

    useEffect(() => {
        if (features.adFeatures) {
            setAdSet(new Set(features.adFeatures), false)
            return;
        }

        const adFeatures = new Set();
        for (let i = 0; i < AdPaths.length; i++) {
            const path = AdPaths[i];
            const val = _.get(features, path);

            if (val)
                adFeatures.add('@' + path)
        }

        ['Entertainment', 'Feature', 'Comfort'].forEach(group =>
            Object.keys(features[group]).forEach(prop => adFeatures.add(`@${group}.${prop}`)));

        setAdSet(adFeatures, false)

    }, [data])

    const setAdSet = (data, sync = true) => {
        setAdvertiseSet(data);

        const newFeatures = { ...features, adFeatures: Array.from(data) };
        setFeatures(newFeatures);

        sync && onChange && onChange(newFeatures)

    }

    const handleChange = (value, group, label) => {
        const path = group + '.' + label;
        const current = _.get(features, path);

        if (value.hasOwnProperty('show') && edit == 'AdFeatures') {
            if (value.show)
                adSet.add('@' + path);
            else
                adSet.delete('@' + path);

            setAdSet(new Set(adSet));
            return;
        }

        const newFeatures = _.set({ ...features }, path, { ...current, ...value });
        setFeatures(newFeatures);

        onChange && onChange(newFeatures)
    }



    function Tag(props) {
        let { tag, key, disabled, onRemove, classNameRemove, ...other } = props

        tag = getTagValue(tag, features);
        if (!tag)
            return <></>;
        
        return (
            <span key={key} {...other}>
                {tag}
                {!disabled &&
                    <a className={classNameRemove} onClick={(e) => onRemove(key)} />
                }
            </span>
        )
    }
    return (
        <div className="d-flex flex-column">
            <div className="spec-container">
                {/* <Specs group='Vehicle Data' features={features} /> */}
                {editMode && adSet &&
                    <div className="group " data-edit={edit}>
                        <div className="title d-flex">Ad Features
                            <div className="cursor-pointer ms-auto rounded"
                                onClick={e => setEdit(edit == 'AdFeatures' ? '' : 'AdFeatures')}>
                                {edit == 'AdFeatures' ?
                                    <MdOutlineDoneOutline size={30} />
                                    :
                                    <MdEdit size={30} />
                                }
                            </div>
                        </div>
                        <div className="sheet row py-2 px-3">
                            <TagsInput
                                value={Array.from(adSet)}
                                renderTag={Tag}
                                inputProps={{ placeholder: 'Enter Feature Here' }}
                                onChange={newFeatures => setAdSet(new Set(newFeatures))} />
                        </div>
                    </div>
                }

                {editMode && features &&
                    ['Vehicle Data', ...edit != 'AdFeatures' ? ['High Value'] : [], 'Equipment']
                        .map(group => <Specs
                            {...{ group, features, editMode, edit, handleChange, adSet }}
                            insertMode={group == 'Vehicle Data' ?
                                { labels: features['Vehicle Data'] ? vehicleDataProps.filter(item => !features['Vehicle Data'][item]) : vehicleDataProps }
                                : { value: 'Yes' }}
                        />)
                }



                {features && groups.map((group, index) => <Specs {...{ group, features, editMode, edit, handleChange, adSet, collapsed, index }} />)}

                {features.Extras &&
                    <div className="group" style={collapsed ? { display: 'none' } : {}}>
                        <div className="title">Extras</div>
                        <div className="sheet row p-2">
                            {editMode ?
                                <textarea class="form-control" rows="5" value={features.Extras.join('\n')} placeholder="Type each feature in every line"
                                    onChange={e => {
                                        const newFeatures = { ...features, Extras: e.target.value.split('\n') };
                                        setFeatures(newFeatures);
                                        onChange && onChange(newFeatures)
                                    }
                                    }></textarea>
                                :
                                <div className="vehicle-feature-specs">
                                    {features.Extras.map(feature =>
                                        <div className="vehicle-feature-spec">{feature}</div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>

            {onSubmit &&
                <Button
                    className="mt-2"
                    variant="primary"
                    type={"submit"}
                    onClick={e => features && onSubmit(features)}
                // disabled={!formRenderProps.allowSubmit}
                >Submit Features</Button>}
        </div>

    );
}

export default SpecSheet;



function Specs({ group, features, editMode, edit, handleChange, adSet, collapsed, index, insertMode }) {
    const [newProp, setNewProp] = useState({ label: '', value: insertMode ? insertMode.value : '' })
    const [labels, setLabels] = useState(insertMode && insertMode.labels ? insertMode.labels : []);

    if (_.isEmpty(features[group]) && !insertMode)
        return <></>;

    var groupStyle = {};
    if (collapsed && index > 1)
        groupStyle.display = 'none';

    return <div className="group" style={groupStyle}>
        <div className="title">{group}</div>
        <div className="sheet row p-2">
            {features[group] && Object.keys(features[group]).map(prop => {
                const val = features[group][prop];
                let check = val.show;

                if ((!editMode && val.selected == 'No') || (val.show != undefined && !editMode && !val.show))
                    return <></>;

                if (edit == 'AdFeatures')
                    check = adSet.has('@' + group + '.' + prop);


                return <div className="col-xl-6 col-md-12 prop">
                    <div className="d-flex flex-row prop-container">
                        {editMode ?
                            <Checkbox key={Math.random()} value={check} data-edit={edit} label={prop} size='small'
                                className="px-1 label" onChange={e => handleChange({ show: e.value }, group, prop)} />
                            :
                            <div className="label">{prop}</div>}
                        <div className="value-container">
                            <Items val={val} group={group} label={prop} />
                        </div>
                    </div>

                </div>;
            })}
            {editMode && insertMode &&
                <div className="col-xl-6 col-md-12 prop">
                    <div className="d-flex flex-row prop-container">
                        <div className="label px-1">
                            <TextField
                                label='Label'
                                variant="outlined"
                                color="info"
                                fullWidth
                                select={labels.length > 0}
                                size="small"
                                value={newProp.label}
                                onChange={e => setNewProp({ ...newProp, label: e.target.value })}
                            >
                                {labels.map(label =>
                                    <MenuItem value={label}>{label}</MenuItem>
                                )}

                            </TextField>
                        </div>
                        <div className="value-container px-1">
                            <TextField
                                label='Value'
                                variant="outlined"
                                color="info"
                                fullWidth
                                size="small"
                                disabled={insertMode && insertMode.value}
                                value={newProp.value}
                                onChange={e => setNewProp({ ...newProp, value: e.target.value })}
                            />
                        </div>
                        <IconButton color="info" onClick={insertNew()}>
                            <FiSave />
                        </IconButton>
                    </div>

                </div>
            }

        </div>
    </div>;

    function Items({ val, label, group }) {
        if (!editMode && val.selected == 'No') return;

        let classes = '';
        if (val.items.length > 1)
            classes = 'py-2'


        if (editMode && val.items.length > 1)
            return <div >
                {val.items.map((item, i) =>
                    <div className={"row value " + classes}>
                        <div className="col-12">
                            <RadioButton name={label} defaultChecked={item == val.selected} value={item} label={item}
                                size='medium' labelPlacement="after" onChange={e => handleChange({ selected: e.value }, group, label)} />
                        </div>
                    </div>
                )
                }
            </div>
        else
            return typeof val.selected == 'string' && val.selected.toLowerCase() == 'yes' ? '' : val.selected;
    };


    function insertNew() {
        return e => {
            if (!newProp.label)
                return

            const val = newProp.value ? newProp.value : 'Yes';
            handleChange({ items: [val], selected: val, show: true }, group, newProp.label)

            setNewProp({ label: '', value: insertMode && insertMode.value ? insertMode.value : '' });

            setLabels(labels.filter(label => label != newProp.label));
        }
    }
}
