import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import Button from 'react-bootstrap/Button';

const EditAppointment= () => {
  const { id } = useParams();
  const [appointment, setAppointment] = useState();

  useEffect(() => {
    db.collection('appointments').doc(id).onSnapshot(doc => {
      setAppointment(doc.data());
    })
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    db.collection('appointments').doc(id).update({
      firstName: appointment.firstName,
      lastName: appointment.lastName,
      emailAddress: appointment.emailAddress,
      phoneNumber: appointment.phoneNumber,
      selectedTimeSlot: appointment.selectedTimeSlot,
      vehicleDescription: appointment.vehicleDescription,
    })
  }
      
  const updateField = (e) => {
    e.persist();
    setAppointment(prevInputs => ({ ...prevInputs, [e.target.name]: e.target.value }));
  }
  
  return (
    <section className="inventory-container">
      {appointment && 
        <div className="">
          <form onSubmit={handleSubmit}>            
            <label className="mb-2">First Name:</label>
            <input type="text" className="form-control mb-3" placeholder="" value={appointment.firstName}  name="firstName" onChange={updateField} required/>

            <label className="mb-2">Last Name:</label>
            <input type="text" className="form-control mb-3" placeholder="" value={appointment.lastName}  name="lastName" onChange={updateField} required/>


            <label className="mb-2">Email Address:</label>
            <input type="email" className="form-control mb-3" placeholder="" value={appointment.emailAddress}  name="emailAddress" onChange={updateField} required/>


            <label className="mb-2">Phone Number:</label>
            <input type="text" className="form-control mb-3" placeholder="" value={appointment.phoneNumber}  name="phoneNumber" onChange={updateField} required/>

            <label className="mb-2">Selected TimeSlot:</label>
            <input type="text" className="form-control mb-3" placeholder="" value={appointment.selectedTimeSlot}  name="selectedTimeSlot" onChange={updateField} required/>


            <label className="mb-2">Vehicle Description:</label>
            <input type="text" className="form-control mb-3" placeholder="" value={appointment.vehicleDescription}  name="vehicleDescription" onChange={updateField} required/>

            <label className="mb-2">Upload Date:</label>
            <input type="text" className="form-control mb-3" disabled placeholder="" name="date" value={appointment.date}/>

            <Button type="submit" className="btn btn-primary">Update</Button>
          </form>
        </div>
      }
    </section>
  );
}

export default EditAppointment;