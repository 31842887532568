import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from "./firebase";

const StepFour = () => {
  const [inquiry, setInquiry] = useState();

  const [vehicleInformation, setVehicleInformation] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [customerInformation, setCustomerInformation] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    db.collection('inquiries').doc(id).onSnapshot(doc => {
      setInquiry(doc.data());

      if(doc.data()) {
        if(doc.data().vehicleInformation !== undefined) {
          setVehicleInformation(doc.data().vehicleInformation);
        }
  
        if(doc.data().planType !== undefined) {
          setSelectedOption(doc.data().planType);
        }
  
        if(doc.data().customerInformation !== undefined) {
          setCustomerInformation(doc.data().customerInformation);
        }
      }
    })
  }, []);

  const handleChange = (e) => {
    // const { name, value, type, checked } = e.target;
    // setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
  };

  const previousStep = (e) => {
    e.preventDefault();
    navigate(`/warranty/${id}/customer`);
  };

  const printButton = (e) => {
    window.print();
  };

  return (
    <>
      <div className="warranty-progress-tracker">
        <div>VEHICLE INFORMATION</div>
        <div>/</div>
        <div>PROGRAMS</div>
        <div>/</div>
        <div>CUSTOMER INFORMATION</div>
        <div>/</div>
        <div className="warranty-progress-selected">SUMMARY</div>
      </div>

      <form className="warranty-form">
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="selectedOption"
              value={id}
              placeholder=" "
              disabled
            />
            <label>Inquiry Reference Number</label>
          </div>
        </div>
      </form>

      <div className="inventory-title" style={{padding: "30px 0"}}>Vehicle Information</div>
      <form className="warranty-form">
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="stockNumber"
              value={vehicleInformation.stockNumber}
              placeholder=" "
              disabled
            />
            <label>Stock Number</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="vin"
              value={vehicleInformation.vin}
              placeholder=" "
              disabled
            />
            <label>VIN</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="make"
              value={vehicleInformation.make}
              placeholder=" "
              disabled
            />
            <label>Make</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="model"
              value={vehicleInformation.model}
              placeholder=" "
              disabled
            />
            <label>Model</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="trim"
              value={vehicleInformation.trim}
              placeholder=" "
              disabled
            />
            <label>Trim</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="year"
              value={vehicleInformation.year}
              placeholder=" "
              disabled
            />
            <label>Year</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="number"
              className="form-control"
              name="purchasePrice"
              value={vehicleInformation.purchasePrice}
              placeholder=" "
              step="0.01"
              disabled
            />
            <label>Purchase Price / Lease Value ($)</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="odometerReading"
              value={vehicleInformation.odometerReading}
              placeholder=" "
              disabled
            />
            <label>Odometer Reading (KMs)</label>
          </div>
        </div>
      </form>

      {selectedOption && 
      <>
        <div className="inventory-title" style={{padding: "30px 0"}}>Selected Program</div>
        <form className="warranty-form">
          <div className="form-group" style={{gap: '10px'}}>
            <div className="form-floating form-child">
              <input
                type="text"
                className="form-control"
                name="selectedOption"
                value={selectedOption.split(" / ").slice(0, -1).join(" / ")}
                placeholder=" "
                disabled
              />
              <label>Package (Plan Type / Duration / Milage / Max Claim)</label>
            </div>

            <div className="form-floating form-child">
              <input
                type="text"
                className="form-control"
                name="selectedOption"
                value={selectedOption.split(" / ").pop()}
                placeholder=" "
                disabled
              />
              <label>Package Purchase Price</label>
            </div>
          </div>
        </form>
      </>}

      <div className="inventory-title" style={{padding: "30px 0"}}>Customer Information</div>
      <form className="warranty-form">
        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={customerInformation.firstName}
              placeholder=" "
              disabled
            />
            <label>First Name</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="inital"
              value={customerInformation.inital}
              placeholder=" "
              disabled
            />
            <label>Inital</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={customerInformation.lastName}
              placeholder=" "
              disabled
            />
            <label>Last Name</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="mailingAddress"
              value={customerInformation.mailingAddress}
              placeholder=" "
              disabled
            />
            <label>Mailing Address</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="apartmentNumber"
              value={customerInformation.apartmentNumber}
              placeholder=" "
              disabled
            />
            <label>Apartment Number</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="postalCode"
              value={customerInformation.postalCode}
              placeholder=""
              disabled
            />
            <label>Postal Code</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="city"
              value={customerInformation.city}
              placeholder=" "
              disabled
            />
            <label>City</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="province"
              value={customerInformation.province}
              placeholder=""
              disabled
            />
            <label>Province</label>
          </div>
        </div>

        <div className="form-group" style={{gap: '10px'}}>
          <div className="form-floating form-child">
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              value={customerInformation.phoneNumber}
              placeholder="(204) 123-1234"
              disabled
            />
            <label>Phone Number</label>
          </div>

          <div className="form-floating form-child">
            <input
              type="email"
              className="form-control"
              name="emailAddress"
              value={customerInformation.emailAddress}
              placeholder=" "
              disabled
            />
            <label>Email Address</label>
          </div>
        </div>

        <div className="custom-warranty-button-container">
          <Button className="custom-next-button financing-button" onClick={(e) => previousStep(e)} id="printPageButton">Back</Button>
          <Button className="custom-next-button financing-button" onClick={(e) => printButton(e)} id="printPageButton">Print</Button>
        </div>
      </form>
    </>
  );
};

export default StepFour;
