import ContactForm from './ContactForm';

const AboutUs = () => {
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  document.body.scrollTop = 0; // For Safari
  
  return (
    <section className="inventory-container">
      <div className="inventory-title">About Us</div>

      <div className="about-us-container">
        <div className="about-us-child">
          <div className="car-md-description dark"><span className="car-highlight dark">Who </span> Are We?</div>
          <div className="car-paragraph gray" style={{width:`100%`}}>We are not merely an automotive company; we are the architects of elegance on
           wheels. Our legacy is one of unparalleled craftsmanship, where every detail is an ode to artistry. With us, you don't just drive; you
          experience the embodiment of serenity in motion.</div>
        </div>

        <div className="about-us-child">
          <div className="car-md-description dark"><span className="car-highlight">Our </span> Mission</div>
          <div className="car-paragraph gray" style={{width:`100%`}}>Our mission is to redefine the road, setting new standards for automotive
           excellence. We craft vehicles that seamlessly marry cutting-edge technology, extraordinary performance, and unparalleled comfort.
          In our relentless pursuit of sustainability and innovation, we are steering towards a greener, more promising future on the open road.</div>
        </div>

        <div className="about-us-child">
          <div className="car-md-description dark"><span className="car-highlight">What </span> we do</div>
          <div className="car-paragraph gray" style={{width:`100%`}}>Step into our world, where automotive dreams become reality. We are the artisans
           of premier vehicles, committed to delivering nothing less than exceptional quality and luxury. From graceful sedans that whisper
          sophistication to powerful SUVs that roar with innovation, our vehicles are masterpieces in motion.</div>
        </div>

        <div className="about-us-child"> 
          <div className="car-md-description dark"><span className="car-highlight">Our </span> commitment</div>
          <div className="car-paragraph gray" style={{width:`100%`}}>Our commitment knows no bounds. We are driven by an unyielding passion for
           excellence and an unwavering dedication to innovation. With every vehicle we create, we promise unparalleled quality, unmatched reliability,
          and an infusion of luxury that transcends the ordinary. We are here to elevate your driving experience to new, breathtaking heights.</div>
        </div>
      </div>

      <section className="car-contact" style={{margin:`100px 0`}}>
        <div className="car-contact-container">
          <div className="car-contact-information">
            <div className="car-dealership">
              <div className="car-dealership-title dark">Serene Auto</div>
              <div className="car-dealership-info dark">3370 Wilkes Ave, Winnipeg, MB R3S 1A8</div>
              <div className="car-dealership-info dark">+1 (431) 588-4511</div>
              <div className="car-dealership-info dark">Info@sereneauto.ca</div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d329346.8510603042!2d-97.258968!3d49.842039!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea0b232351466b%3A0xb296177e8d08fad6!2s3370%20Wilkes%20Ave%2C%20Winnipeg%2C%20MB%20R3S%201A8%2C%20Canada!5e0!3m2!1sen!2sus!4v1694051050273!5m2!1sen!2sus" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <ContactForm/>
        </div>
      </section>
      
      {/* <section className="car-help padding-fix" style={{backgroundColor: 'white'}}>
        <div className="car-help-container">
          <div className="car-help-description">
            <div className="car-md-description dark"><span className="car-highlight">WHY </span> CHOOSE US?</div>
            <div className="car-paragraph dark">Find the perfect used car for your needs at our dealership. Explore our extensive inventory of high-quality used vehicles and make a smart choice for your next car purchase. Trust us to provide reliable options and exceptional customer service throughout the buying process.</div>
          </div>
        </div>

        <div className="car-help-steps">
          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><FaRegHandshake size={35} color="#e11d4b"/></div>
              <div className="car-step-number light">01</div>
            </div>
            <div className='help-desc light'>Trusted By Thousands</div>
            <div className='help-para gray'>As a respected industry frontrunner, we are deeply devoted to delivering top-notch quality and exceptional service. Embark on a journey of automotive excellence with Serene Auto.</div>
          </div>

          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><GiHomeGarage size={35} color="#e11d4b"/></div>
              <div className="car-step-number light">02</div>
            </div>
            <div className='help-desc light'> Wide Range Of Vehicles</div>
            <div className='help-para gray'>Choose from our versatile range of vehicles, each designed to suit various preferences and lifestyles. Whether you seek elegance, power, or adventure, we have the perfect ride for you.</div>
          </div>

          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><LiaUserClockSolid size={35} color="#e11d4b"/></div>
              <div className="car-step-number light">03</div>
            </div>
            <div className='help-desc light'> Faster Buy & Sell</div>
            <div className='help-para gray'>Our efficient Buy & Sell service ensures quick and hassle-free transactions for all your automotive needs. Whether buying or selling, we prioritize speed and simplicity to get you on the road or find the perfect buyer in no time.</div>
          </div>
        </div>
      </section> */}
    </section>
  );
}

export default AboutUs;