import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { auth } from "./firebase";
import HomePage from './HomePage';
import Inventory from './Inventory';
import AboutUs from './AboutUs';
import Warranty from './Warranty';

import CarDetail from './CarDetail';
import AddListing from './AddListing';
import Error from './Error';
import SignIn from './SignIn';
import ContactPage from './ContactPage';
import BookAppointment from './BookAppointment';
import ViewAllAppointments from './ViewAllAppointments';
import EditAppointment from './EditAppointment';
import ViewWarrantySettings from './ViewWarrantySettings';
import ViewInquiries from './ViewInquiries';
import AddWarranty from './AddWarranty';
import EditWarranty from './EditWarranty';

import Header from './Header';
import Footer from './Footer';
import FinancingPage from "./FinancingPage";
import FeatureContainer from "./features/FeatureContainer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CarContainer from "./CarContainer";

function App() {
  const [currentUser, setCurrentUser] = useState(""); 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      console.log(currentUser);
    });
    
    return unsubscribe;
  }, []);

  const signIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#e11d4b',
      },
      secondary: {
        main: '#f44336',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Header/>
      <main>
        <Routes>
          <Route path="/" element={<HomePage/>}></Route>
          <Route path="/inventory/:id?"  element={<CarContainer currentUser={currentUser}/>}></Route>
          {/* <Route path="/inventory"  element={<Inventory />}></Route>
          <Route path="/inventory/:id" element={<CarDetail currentUser={currentUser}/>}></Route> */}
          {/* <Route path="/warranty/:id" element={<Warranty/>}></Route> */}
          <Route path="/warranty/:id/*" element={<Warranty/>}></Route>
          <Route path="/warranty/:id/"element={<Navigate to="/error" />}></Route>

          {/* <Route path="/warranty/:id/one" element={<StepOneForm/>}></Route>
          <Route path="/warranty/:id/two" element={<StepTwoForm/>}></Route>
          <Route path="/warranty/:id/three" element={<StepThreeForm/>}></Route> */}

          <Route path="/about"  element={<AboutUs />}></Route>
          <Route path="/financing" element={<FinancingPage/>}></Route>
          <Route path="/*" element={<Navigate to="/error" />} />
          <Route path="/contact" element={<ContactPage/>}></Route>
          <Route path="/appointment" element={<BookAppointment/>}></Route>

          <Route path="/error" element={<Error/>}></Route>

          <Route path="/add-by-vin" element={currentUser ? <FeatureContainer /> : <SignIn signIn={signIn} /> }/>
          <Route path="/addListing" element={currentUser ? <AddListing /> : <SignIn signIn={signIn} /> }/>
          <Route path="/addListing/:id" element={currentUser ? <AddListing /> : <SignIn signIn={signIn} /> }/>

          <Route path="/view-appointments" element={currentUser ? <ViewAllAppointments /> : <SignIn signIn={signIn} /> }/>
          <Route path="/view-appointments/edit/:id" element={currentUser ? <EditAppointment /> : <SignIn signIn={signIn} /> }/>

          <Route path="/view-warranty-settings" element={currentUser ? <ViewWarrantySettings /> : <SignIn signIn={signIn} /> }/>
          <Route path="/view-warranty-settings/add" element={currentUser ? <AddWarranty /> : <SignIn signIn={signIn} /> }/>
          <Route path="/view-warranty-settings/edit/:id" element={currentUser ? <EditWarranty /> : <SignIn signIn={signIn} /> }/>
          
          <Route path="/view-inquiries" element={currentUser ? <ViewInquiries /> : <SignIn signIn={signIn} /> }/>

          <Route path="/admin" element={<SignIn signIn={signIn} currentUser={currentUser}/>} />
          <Route path="/view-appointments" element={<ViewAllAppointments/>}></Route>
        </Routes>
      </main>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
