import React, { useState, useEffect, useRef} from 'react';
import { db } from "./firebase";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import WarrantyOption from './WarrantyOption';

const ViewWarrantySettings = () => {
  const [warranties, setWarranties] = useState([]);

  useEffect(() => {
    const unsubscribe = db.collection('Warranty').onSnapshot(snapshot => {
      const warranties = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setWarranties(warranties);
    });

    return unsubscribe;
  }, []);

  // Separate warranties by plan type
  const groupedWarranties = {
    Platinum: [],
    Titanium: [],
    Gold: [],
    Silver: [],
    Bronze: [],
  };

  warranties.forEach(warranty => {
    const { planType } = warranty;
    groupedWarranties[planType].push(warranty);
  });

  // Sort warranties by year and mileage for each plan type in reverse order
  Object.keys(groupedWarranties).forEach(planType => {
    groupedWarranties[planType].sort((a, b) => {
      const yearOrder = {
        '3 Month': 1,
        '6 Month': 2,
        '1 Year': 3,
        '2 Year': 4,
        '3 Year': 5,
        '4 Year': 6,
      };
  
      const mileageOrder = {
        '20,000 KMs': 1,
        '40,000 KMs': 2,
        '60,000 KMs': 3,
        '80,000 KMs': 4,
        'Unlimited': 5
      };
  
      const maxClaimOrder = {
        '$1,000': 1,
        '$2,000': 2,
        '$3,000': 3,
        '$4,000': 4,
        '$5,000': 5,
        '$10,000': 6,
        '$25,000': 7,
        'Retail': 8
      };
  
      // Compare by year
      if (a.duration !== b.duration) {
        return yearOrder[a.duration] - yearOrder[b.duration];
      }
  
      // Compare by mileage
      if (a.mileage !== b.mileage) {
        return mileageOrder[a.mileage] - mileageOrder[b.mileage];
      }
  
      // Compare by maxClaim
      return maxClaimOrder[a.maxClaim] - maxClaimOrder[b.maxClaim];
    });
  });
  
	return (
    <>
      <section className="inventory-container" >
        <div className="inventory-title" style={{display: 'flex', justifyContent: 'space-between'}}>
          <p style={{marginBottom: '0'}}>Warranty Settings</p>
          <Button className="custom-button show-result-fix"><Link to={`/view-warranty-settings/add`}>Add Warranty</Link></Button>
        </div>
      </section>
      
      {warranties && warranties.length > 0 ? (
      <section className="inventory-container">
        {['Platinum', 'Titanium', 'Gold', 'Silver', 'Bronze'].map(planType => (
          <div key={planType}>
            <div className={`inventory-title ${planType}`} style={{margin: '20px 0'}}>{planType}</div>
            {groupedWarranties[planType].map(warranty => (
              <WarrantyOption warranty={warranty} key={warranty.id}></WarrantyOption>
            ))}
          </div>
        ))} 
      </section>) :      
        <div className="spinner-container">
          <div role="status" className="spinner-border spinner-styling">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>}
    </>
	);
}
  
export default ViewWarrantySettings;

