import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { BsXSquareFill } from "react-icons/bs";
import { db, storage } from "./firebase";
import Form from 'react-bootstrap/Form';
import { useParams, Link } from "react-router-dom";
import _, { isEmpty } from 'lodash';
import AdTemplate from './features/AdTemplate';
import TagsInput from 'react-tagsinput';
import SpecSheet from './features/SpecSheet';

export const AddListing = ({ defaultFeatures = {}, vin, onSubmit }) => {
  //NOT YET BEING USED
  // const [series, setSeries] = useState('');
  // const [engineCylinders, setEngineCylinders] = useState('');
  // const [fuelType, setFuelType] = useState('');
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [features, setFeatures] = useState(defaultFeatures)
  const [VIN, setVIN] = useState(vin || '');
  const [stockNumber, setStockNumber] = useState('');
  const [status, setStatus] = useState('Available');
  const [driveType, setDriveType] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [trim, setTrim] = useState('');
  const [year, setYear] = useState('');
  const [interiorColour, setInteriorColour] = useState('');
  const [exteriorColour, setExteriorColour] = useState('');
  const [bodyType, setBodyType] = useState('Other');
  const [askingPrice, setAskingPrice] = useState('');
  const [doors, setDoors] = useState('');
  const [seats, setSeats] = useState('');
  const [transmission, setTransmission] = useState('');
  const [vehicleCondition, setVehicleCondition] = useState('Used');
  const [vehicleKilometers, setVehicleKilometers] = useState();
  const [engineType, setEngineType] = useState('');
  const [fuelEconomy, setFuelEconomy] = useState('');
  const [fuelEconomyCity, setFuelEconomyCity] = useState('');
  const [fuelEconomyHighWay, setFuelEconomyHighWay] = useState('');
  const [description, setDescription] = useState('');
  const [carFaxPDFUrl, setCarFaxPDFUrl] = useState('');
  const [uploadDate, setUploadDate] = useState(moment().format('LLL'));

  const [inputEquipmentText, setInputEquipmentText] = useState('');
  const [inputHighValueText, setInputHighValueText] = useState('');

  const [vehicleEquipmentFeatures, setVehicleEquipmentFeatures] = useState([]);
  const [titleHighValue, setTitleHighValue] = useState([]);
  const [vehicleHighValueFeatures, setVehicleHighValueFeatures] = useState([]);

  const [reportedAccidents, setReportedAccidents] = useState(false);
  const [lowKilometers, setLowKilometers] = useState(false);
  const [topCondition, setTopCondition] = useState(false);
  const [oneOwner, setOneOwner] = useState(false);
  const [featured, setFeatured] = useState(false);

  const [galleryHighlight, setGalleryHighlight] = useState('');
  const [bannerImages, setBannerImages] = useState([]);
  const { id } = useParams();

  async function setNewStockNumber() {
    //.where('stockNumber', '>=', 'SE').where('stockNumber', '<', 'SF')
    //.replace('SE', '')
    const query = await db.collection('inventory').orderBy('stockNumber', 'desc').limit(1).get()
    if (query.docs.length > 0) {
      let num = query.docs[0].data().stockNumber;
      // num = String();

      setStockNumber(parseInt(num) + 1);
    }
  }

  // add Data from features to form
  useEffect(() => {
    if (_.isEmpty(features) || !noResultsFound)
      return;

    const get = path => _.get(features, path, '');

    const dtAdesa = get('Vehicle Data.Drivetrain.selected');

    if (dtAdesa)
      setDriveType(dtAdesa.includes('all') ? 'AWD' : dtAdesa.includes('4') ? '4x4' : dtAdesa.includes('rear') ? 'RWD' : dtAdesa.includes('front') ? 'FWD' : '')
    else {

      const allDriveLines = { FWD: "FWD", RWD: "RWD", AWD: "AWD", '4WD': "4x4", '2WD': "4x2" };

      const driveLine = get('Drivetrain.Driveline.selected').toUpperCase();

      setDriveType(
        allDriveLines[Object.keys(allDriveLines).find(key => driveLine.includes(key))]
        || '');
    }

    const allBodyTypes = ['Sedan', 'SUV', 'Truck', 'Hatchback', 'Van'];

    const body = (get('Base.Body Style.selected') || get('Vehicle Data.Body Style.selected')).toLowerCase();
    setBodyType(
      allBodyTypes.find(type =>
        body.includes(type.toLowerCase()) && type)
      || 'Other');



    let transmission = (get('Vehicle Data.Transmission.selected', '').replaceAll('-', '').trim() || get('Drivetrain.Transmission.selected')).toLowerCase();
    const isAuto = transmission.includes('auto') || /[0-9]+a/.test(transmission);
    const isManual = transmission.includes('manual') || /[0-9]+m/.test(transmission);

    if (transmission.includes('continuous') || transmission.includes('cvt'))
      transmission = 'Continuously-Variable';
    else if (isAuto && isManual)
      transmission = 'Automated-Manual';
    else if (isAuto)
      transmission = 'Automatic';
    else if (isManual)
      transmission = 'Manual';
    else transmission = '';

    setTransmission(transmission);


    setMake(get('Vehicle Data.Make.selected') || get('Base.Make.selected'))
    setModel(get('Vehicle Data.Model.selected') || get('Base.Model.selected'))
    setTrim(get('Vehicle Data.Trim.selected') || get('Base.Trim Level.selected'))
    setYear(get('Vehicle Data.Year.selected') || get('Base.Model Year.selected'))
    setDoors(get('Base.Doors.selected') || get('Vehicle Data.Doors.selected'))
    setSeats(get('Seating.Seats.selected'))
    setEngineType(get('Vehicle Data.Engine.selected') || get('Drivetrain.Engine Type.selected'))

    setFuelEconomyCity(get('Fuel.Fuel Economy-city.selected'))
    setFuelEconomyHighWay(get('Fuel.Fuel Economy-highway.selected'))


    const interior = get('Vehicle Data.Interior Color.selected') || get('Colors.Interior Color.selected')
    setInteriorColour(!interior || interior == 'No' ? '' : interior);

    const exterior = get('Vehicle Data.Exterior Color.selected') || get('Colors.Exterior Color.selected')
    setExteriorColour(!exterior || exterior == 'No' ? '' : exterior);


    setVehicleKilometers(get('Vehicle Data.odometer.selected'));


  }, [features, noResultsFound]);


  useEffect(() => {
    const unsubscribe = db.collection('inventory').doc(id).onSnapshot(doc => {
      if (doc.data() == undefined) {
        setNewStockNumber();
        setNoResultsFound(true);
      } else {
        setVIN(doc.data().VIN);
        setStockNumber(doc.data().stockNumber);
        setFeatures(doc.data().features || {});
        setStatus(doc.data().status)
        setDriveType(doc.data().driveType);
        setMake(doc.data().make);
        setModel(doc.data().model);
        setTrim(doc.data().trim);
        setYear(doc.data().year);
        setInteriorColour(doc.data().interiorColour);
        setExteriorColour(doc.data().exteriorColour);
        setBodyType(doc.data().bodyType);
        setAskingPrice(doc.data().askingPrice);
        setDoors(doc.data().doors);
        setSeats(doc.data().seats);
        setTransmission(doc.data().transmission);
        setVehicleCondition(doc.data().vehicleCondition);
        setVehicleKilometers(doc.data().vehicleKilometers);
        setEngineType(doc.data().engineType);
        setFuelEconomy(doc.data().fuelEconomy);
        setFuelEconomyCity(doc.data().fuelEconomyCity || '');
        setFuelEconomyHighWay(doc.data().fuelEconomyHighWay || '');
        setDescription(doc.data().description);
        setCarFaxPDFUrl(doc.data().carFaxPDFUrl);
        setInputHighValueText(doc.data().inputHighValueText);
        setInputEquipmentText(doc.data().inputEquipmentText);
        setVehicleEquipmentFeatures(doc.data().vehicleEquipmentFeatures || []);
        setTitleHighValue(doc.data().titleHighValue || []);
        setVehicleHighValueFeatures(doc.data().vehicleHighValueFeatures || []);
        setReportedAccidents(doc.data().reportedAccidents);
        setLowKilometers(doc.data().lowKilometers);
        setTopCondition(doc.data().topCondition);
        setOneOwner(doc.data().oneOwner);
        setFeatured(doc.data().featured);
        setBannerImages(doc.data().bannerImages);
      }
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.body.scrollTop = 0; // For Safari
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!vin)
      fetchCarDetails();
  }, [VIN && VIN.length === 17]);

  const fetchCarDetails = async () => {
    const fetch = require('node-fetch');
    const URL = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVINValues/${VIN}Batch?format=json`;

    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        if (!id) {
          setDriveType(result.Results[0].DriveType);
          setMake(result.Results[0].Make);
          setModel(result.Results[0].Model);
          setYear(result.Results[0].ModelYear);
          setTransmission(result.Results[0].TransmissionStyle);
          setDoors(result.Results[0].Doors);
          setSeats(result.Results[0].Seats);
        }

        // setVIN(result.Results[0].VIN);
        // setBodyType(result.Results[0].BodyClass);
        // setEngineCylinders(result.Results[0].EngineCylinders);
        // setSeries(result.Results[0].Series);
        // setFuelType(result.Results[0].FuelTypePrimary);
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  };

  const uploadFile = (file, directory, order) => {
    const vehicleSubFolder = `${year} - ${make} ${model} ${trim}`;
    const uploadTask = storage.ref(`${directory}/${vehicleSubFolder}/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        console.log(`Uploading ${file.name}: ${prog}%`);
      },
      (error) => {
        console.error(`Error uploading ${file.name}: ${error.message}`);
      },
      () => {
        storage
          .ref(`${directory}/${vehicleSubFolder}`)
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            if (directory === "files") {
              setBannerImages(prevInputs => ([...prevInputs, extractBannersInfo(url, order)]));
              // setBannerImages(prevInputs => ([...prevInputs, { id: generateId(), carousel_Image: url , order}]));
            } else if (directory === "pdfs") {
              setCarFaxPDFUrl(url);
            }
            console.log(`Uploaded ${file.name}: ${url}`);
          });
      }
    );
  };

  const uploadFileToStorage = (e) => {
    e.preventDefault();
    const imageInput = document.querySelectorAll('input[type="file"]')[0];
    const files = imageInput.files;

    if (files.length === 0) {
      console.log("No files selected for upload.");
      return;
    }

    let currentIndex = 0; // Initialize the index counter

    for (const file of files) {
      uploadFile(file, 'files', currentIndex);
      currentIndex++;
    }
  };

  const uploadPDFFileToStorage = (e) => {
    e.preventDefault();
    const PDFInput = document.querySelectorAll('input[type="file"]')[1];
    const file = PDFInput.files;

    if (file.length === 0) {
      console.log("No file selected for upload.");
      return;
    }

    uploadFile(file[0], 'pdfs');
  };

  const extractBannersInfo = (url, order) => {
    const originalImageUrl = url || "";
    const bannerId = generateId();

    const image300x300Url = originalImageUrl.replace(/(\.\w+)(\?alt=media&token=.+)$/, `_300x300$1$2`);
    const image600x600Url = originalImageUrl.replace(/(\.\w+)(\?alt=media&token=.+)$/, `_600x600$1$2`);
    const image1000x1000Url = originalImageUrl.replace(/(\.\w+)(\?alt=media&token=.+)$/, `_1000x1000$1$2`);

    // const image300x300Url = originalImageUrl.replace(/(\.jpg)(\?alt=media&token=.+)$/, `_300x300$1$2`);
    // const image600x600Url = originalImageUrl.replace(/(\.jpg)(\?alt=media&token=.+)$/, `_600x600$1$2`);
    // const image1000x1000Url = originalImageUrl.replace(/(\.jpg)(\?alt=media&token=.+)$/, `_1000x1000$1$2`);

    const carouselImage = [image300x300Url, image600x600Url, image1000x1000Url];

    const bannerInfo = {
      carousel_Image: carouselImage,
      id: bannerId,
      order: order || 0
    };

    return bannerInfo;
  }

  function handleRemoveImage(image) {
    setBannerImages(bannerImages.filter((a) => a.id !== image.id));
    setGalleryHighlight('');
  }

  function generateId() {
    return Date.now().toString() + Math.random().toString(36).substr(2);
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (noResultsFound) {
      db.collection('inventory').add({
        VIN,
        stockNumber,
        status,
        driveType,
        make,
        model,
        trim,
        year,
        interiorColour,
        exteriorColour,
        bodyType,
        askingPrice,
        doors,
        seats,
        transmission,
        vehicleCondition,
        vehicleKilometers,
        engineType,
        fuelEconomy,
        fuelEconomyCity,
        fuelEconomyHighWay,
        description,
        carFaxPDFUrl,
        inputHighValueText,
        inputEquipmentText,
        features,
        titleHighValue,
        vehicleEquipmentFeatures,
        vehicleHighValueFeatures,
        reportedAccidents,
        lowKilometers,
        topCondition,
        oneOwner,
        featured,
        bannerImages,
        uploadDate,
        inputEquipmentText,
        inputHighValueText,
      })
        .then((docRef) => {
          setVIN('');
          setNewStockNumber();
          setStatus('Available');
          setFeatures({});
          setDriveType('');
          setMake('');
          setModel('');
          setTrim('');
          setYear('');
          setInteriorColour('');
          setExteriorColour('');
          setBodyType('');
          setAskingPrice('');
          setDoors('');
          setSeats('');
          setTransmission('');
          setVehicleCondition('Used');
          setVehicleKilometers('');
          setEngineType('');
          setFuelEconomy('');
          setFuelEconomyCity('');
          setFuelEconomyHighWay('');
          setDescription('');
          setCarFaxPDFUrl('');
          setInputHighValueText('');
          setInputEquipmentText('');
          setTitleHighValue([])
          setVehicleEquipmentFeatures([]);
          setVehicleHighValueFeatures([]);
          setReportedAccidents(false);
          setLowKilometers(false);
          setTopCondition(false);
          setOneOwner(false);
          setFeatured(false);
          setGalleryHighlight('');
          setBannerImages([]);
          onSubmit && onSubmit(docRef.id);
        })
        .catch(() => {
          // show error message
        });
    } else {
      db.collection('inventory').doc(id).update({
        VIN: VIN,
        stockNumber: stockNumber,
        status: status,
        driveType: driveType,
        make: make,
        model: model,
        trim: trim,
        year: year,
        interiorColour: interiorColour,
        exteriorColour: exteriorColour,
        bodyType: bodyType,
        askingPrice: askingPrice,
        doors: doors,
        seats: seats,
        transmission: transmission,
        vehicleCondition: vehicleCondition,
        vehicleKilometers: vehicleKilometers,
        engineType: engineType,
        fuelEconomy: fuelEconomy,
        fuelEconomyCity: fuelEconomyCity,
        fuelEconomyHighWay: fuelEconomyHighWay,
        description: description,
        carFaxPDFUrl: carFaxPDFUrl,
        // uploadDate: uploadDate,
        inputEquipmentText: inputEquipmentText,
        inputHighValueText: inputHighValueText,
        features,
        titleHighValue,
        vehicleEquipmentFeatures: vehicleEquipmentFeatures,
        vehicleHighValueFeatures: vehicleHighValueFeatures,
        reportedAccidents: reportedAccidents,
        lowKilometers: lowKilometers,
        topCondition: topCondition,
        oneOwner: oneOwner,
        featured: featured,
        bannerImages: bannerImages,
      })
    }
  };

  // const formatInputAsArray = (text) => {
  //   return text
  //     .split(/\n/)
  //     .map((line) => line.trim())
  //     .filter((line) => line !== '')
  //     .map((line) => line
  //       .split(/(?=[A-Z])/)
  //       .map((part) => part.trim())
  //       .join(' ')
  //     );
  // };

  const formatInputAsArray = (text) => {
    const isAllLowercase = (word) => /^[a-z]+$/.test(word);
    const isAllUppercase = (word) => /^[A-Z]+$/.test(word);

    return text
      .split(/\n/)
      .map((line) => line.trim())
      .filter((line) => line !== '')
      .map((line) => line
        .split(/(?=[A-Z])/)
        .map((part) => part.trim())
        .map((part) => {
          // Remove dashes at the start of words
          if (part.startsWith('-')) {
            part = part.substring(1);
          }
          return (isAllLowercase(part) || isAllUppercase(part)) ? part : part + ' ';
        })
        .join('')
      );
  };

  const handleEquipmentChange = (e) => {
    const newText = e.target.value;
    setInputEquipmentText(newText);
    setVehicleEquipmentFeatures(formatInputAsArray(newText));
  };

  const handleHighValueChange = (e) => {
    const newText = e.target.value;
    setInputHighValueText(newText);
    setVehicleHighValueFeatures(formatInputAsArray(newText));
  };

  const handleRemove = (id) => {
    db.collection('inventory').doc(id).delete();
  };

  return (
    <div className="add-listing-container">
      <div className="inventory-title">{noResultsFound ? "ADD" : "UPDATE"} LISTING</div>
      <form onSubmit={handleSubmit} className="add-listing-form">
        <div className="vehicle-overview">

          <div className="car-input-status">
            <div className="vehicle-overview-title">Status:</div>
            <select aria-label="Default select example" className="form-select" required aria-describedby="conditionHelpBlock" value={status} onChange={e => setStatus(e.target.value)}>
              <option value="Available">Available</option>
              <option value="Pending">Pending</option>
              <option value="Sold">Sold</option>
            </select>
          </div>

          <div className="vehicle-title">Vehicle Overview</div>
          <div className="vehicle-overview-specs">
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Vehicle Identification Number (VIN):</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={VIN} onChange={e => setVIN(e.target.value)} required />
                </div>
              </div>
            </div>

            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Stock #:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={'SE' + String(stockNumber).padStart(4, '0')} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Make:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={make} onChange={e => setMake(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Model:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={model} onChange={e => setModel(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Trim:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={trim} onChange={e => setTrim(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Year:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={year} onChange={e => setYear(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Interior Colour:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={interiorColour} onChange={e => setInteriorColour(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Exterior Colour:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={exteriorColour} onChange={e => setExteriorColour(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Body Type:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input" required>
                  <select required aria-label="Default select example" className="form-select" aria-describedby="conditionHelpBlock" value={bodyType} onChange={e => setBodyType(e.target.value)}>
                    <option value="Default"></option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="Truck">Pickup truck</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Van">Van</option>
                    <option value="Other">Other</option>

                    {/* <option value="Wagon">Wagon</option> */}
                    {/* <option value="Convertible">Convertible</option> */}
                    {/* <option value="SportsCar">Sports car</option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Asking Price:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={askingPrice} onChange={e => setAskingPrice(e.target.value)} required />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vehicle-overview">
          <div className="vehicle-title">Vehicle Details</div>
          <div className="vehicle-overview-specs">
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Condition:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <select aria-label="Default select example" className="form-select" required aria-describedby="conditionHelpBlock" value={vehicleCondition} onChange={e => setVehicleCondition(e.target.value)}>
                    <option value="Default"></option>

                    <optgroup label="Brand New">
                      <option value="Brand_New">Brand New</option>
                    </optgroup>

                    <optgroup label="Used">
                      <option value="Used">Certified (Safetied)</option>
                      <option value="As Is">As Is (Project Car)</option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Kilometers:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={vehicleKilometers} onChange={e => setVehicleKilometers(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Transmission:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <select aria-label="Default select example" className="form-select" required aria-describedby="conditionHelpBlock" value={transmission} onChange={e => setTransmission(e.target.value)}>
                    <option value="Default"></option>
                    <option value="Automatic">Automatic Transmission (AT)</option>
                    <option value="Manual">Manual Transmission (MT)</option>
                    <option value="Automated-Manual">Automated Manual Transmission (AM)</option>
                    <option value="Continuously-Variable">Continuously Variable Transmission (CVT)</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Engine:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={engineType} onChange={e => setEngineType(e.target.value)} required />
                </div>
              </div>
            </div>

            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Fuel Economy HighWay:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={fuelEconomyHighWay} onChange={e => setFuelEconomyHighWay(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Fuel Economy City:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={fuelEconomyCity} onChange={e => setFuelEconomyCity(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Doors:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={doors} onChange={e => setDoors(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Seats:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={seats} onChange={e => setSeats(e.target.value)} required />
                </div>
              </div>
            </div>
            <div className="vehicle-overview-spec">
              <div className="vehicle-overview-title">Drivetrain:</div>
              <div className="vehicle-overview-detail">
                <div className="car-input">
                  <select aria-label="Default select example" className="form-select" required aria-describedby="conditionHelpBlock" value={driveType} onChange={e => setDriveType(e.target.value)}>
                    <option value="default"></option>
                    <option value="FWD">FWD</option>
                    <option value="RWD">RWD</option>
                    <option value="AWD">AWD</option>
                    <option value="4x4">4WD</option>
                    <option value="4x2">2WD</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="car-input">
              <label className="mb-2">Series:</label>
              <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={series} onChange={e => setSeries(e.target.value)} required />
            </div> */}

        {/* <div className="car-input">
              <label className="mb-2">Engine Cylinders:</label>
              <input type="text" className="form-control" aria-describedby="titleHelpBlock" placeholder="" value={engineCylinders} onChange={e => setEngineCylinders(e.target.value)} required />
            </div> */}

        {/* <div className="car-input">
              <label className="mb-2">Fuel Type:</label>
              <select aria-label="Default select example" className="form-select" required aria-describedby="conditionHelpBlock" value={fuelType} onChange={e => setFuelType(e.target.value)}>
                <option value="Default"></option>
                <option value="Gasoline">Gasoline</option>
                <option value="Diesel">Diesel</option>
              </select>
            </div> */}

        <div className="car-input">
          <label className="mb-2">Special Note For Car:</label>
          <textarea aria-describedby="descriptionHelpBlock" type="text" className="form-control" rows="4" maxLength="2000" value={description} onChange={e => setDescription(e.target.value)} />
        </div>
        <div className="car-input">
          <label>Title Features</label>
          <TagsInput
            value={titleHighValue}
            inputProps={{ placeholder: 'Add Feature' }}
            onChange={newFeatures => setTitleHighValue(newFeatures)} />
        </div>
        <div className="car-input">
          <label className="mb-2">Ad Text:</label>
          <AdTemplate features={features} detail={{ make, model, year, trim, driveType, engineType, transmission, mileage: vehicleKilometers, askingPrice }} />
        </div>
        <div className="car-input">
          <label className="mb-2">Product Images:</label>
          <div type="text" className="mb-3 input-group">
            <input multiple className="form-control" type='file' /><button type="button" onClick={uploadFileToStorage} className="btn btn-primary" style={{ backgroundColor: 'var(--pink)', border: '1px solid var(--pink)' }}>Upload</button>
          </div>
        </div>

        <div className="bannerImages-gallery" style={{ width: '100%' }}>
          {(bannerImages && bannerImages.length > 0) && <>
            <div className="tab-navigation">
              {bannerImages
                .slice() // Create a shallow copy of the array to avoid mutating the original
                .sort((a, b) => a.order - b.order) // Sort the images by their 'order' property
                .map((banner) => (
                  <div className={`${galleryHighlight.id === banner.id ? 'gallery-tab active-tab' : 'gallery-tab'}`} key={banner.id}>
                    <BsXSquareFill className="remove-icon" onClick={() => handleRemoveImage(banner)} size={25} />
                    <img onClick={() => setGalleryHighlight(banner)} src={banner.carousel_Image[0]} />
                  </div>
                ))}
            </div>

            <div className="tab-highlighted">
              <div className="gallery-tab">
                {galleryHighlight && <img src={galleryHighlight.carousel_Image[2]} />}
              </div>
            </div>
          </>}
        </div>
        {/* 
        <div className="car-input">
          <label className="mb-2">Vehicle Features (Equipment):</label>
          <textarea
            type="text"
            className="form-control"
            rows="4"
            placeholder=""
            value={inputEquipmentText}
            onChange={handleEquipmentChange}
            required />
        </div>
        <div className="vehicle-feature-specs">
          {vehicleEquipmentFeatures.length > 0 && vehicleEquipmentFeatures.map((feature, index) => (
            <div className="vehicle-feature-spec" key={index}>{feature}</div>
          ))}
        </div>

        <div className="car-input">
          <label className="mb-2">Vehicle Features (High Value):</label>
          <textarea
            type="text"
            className="form-control"
            rows="4"
            placeholder=""
            value={inputHighValueText}
            onChange={handleHighValueChange}
            required />
        </div>
        <div className="vehicle-feature-specs">
          {vehicleHighValueFeatures && vehicleHighValueFeatures.map((feature, index) => (
            <div className="vehicle-feature-spec" key={index}>{feature}</div>
          ))}
        </div> */}

        <div className="tier-system-input-container">
          <div className="car-input">
            <label className="mb-2">Tier System:</label>
          </div>
          <Form.Check type="checkbox" label="No Reported Accidents" value={reportedAccidents} checked={reportedAccidents} onChange={e => setReportedAccidents(!reportedAccidents)} />
          <Form.Check type="checkbox" label="Low Kilometers" value={lowKilometers} checked={lowKilometers} onChange={e => setLowKilometers(!lowKilometers)} />
          <Form.Check type="checkbox" label="Top Condition" value={topCondition} checked={topCondition} onChange={e => setTopCondition(!topCondition)} />
          <Form.Check type="checkbox" label="One Owner" value={oneOwner} checked={oneOwner} onChange={e => setOneOwner(!oneOwner)} />
        </div>

        <div className="tier-system-input-container">
          <div className="car-input">
            <label className="mb-2">Home page featured:</label>
          </div>
          <Form.Check type="checkbox" label="Feature on home page" value={featured} checked={featured} onChange={e => setFeatured(!featured)} />
        </div>

        <div className="addListing-tier-container">
          {reportedAccidents &&
            <div className="addListing-tier">
              <img src="https://cdn.carfax.ca/vehicle-history/images/1.0.0/Badges/accident-free@1x.svg" />
            </div>}

          {lowKilometers &&
            <div className="addListing-tier">
              <img src="https://cdn.carfax.ca/vehicle-history/images/1.0.0/Badges/low-kilometers@1x.svg" />
            </div>}

          {topCondition &&
            <div className="addListing-tier">
              <img src="https://cdn.carfax.ca/vehicle-history/images/1.0.0/Badges/top-condition@1x.svg" />
            </div>}

          {oneOwner &&
            <div className="addListing-tier">
              <img src="https://cdn.carfax.ca/vehicle-history/images/1.0.0/Badges/one-owner@1x.svg" />
            </div>}
        </div>

        <div className="car-input">
          <label className="mb-2">CAR FAX:</label>
          <div type="text" className="mb-3 input-group">
            <input className="form-control" type='file' /><button onClick={uploadPDFFileToStorage} type="button" className="btn btn-primary" style={{ backgroundColor: 'var(--pink)', border: '1px solid var(--pink)' }}>Upload</button>
          </div>
        </div>

        <div className="addListing-tier-container">
          {carFaxPDFUrl &&
            <div className="addListing-tier">
              <a href={`${carFaxPDFUrl}`} target="_blank"><img src="https://carfaxcanadabadgingcdn.azureedge.net/content/images/v3/en/Logo_NoBadges.svg" /></a>
            </div>}
        </div>

        {!isEmpty( features) && !noResultsFound &&
          <div className="car-input">
            <label className="mb-2">Features:</label>
            <div type="text" className="mb-3 input-group">
              <SpecSheet vin={VIN} editMode={true}
                data={features}
                onChange={features => setFeatures(features)} />
            </div>
          </div>
        }

        {noResultsFound ?
          <Button type="submit" className="btn btn-primary publish-button custom-button">Add Listing</Button> :
          <div className="update-forward-container">
            <Button type="submit" className="publish-button custom-button">Update Listing</Button>
            <Button type="submit" className="publish-button custom-reset-button"><Link to={`/inventory/${id}`}>Return to listing</Link></Button>
            <Button onClick={() => handleRemove(id)} className="publish-button custom-outline-button"><Link to={`/inventory`}>Delete Listing</Link></Button>
          </div>}
      </form>
    </div>
  );
};

export default AddListing;