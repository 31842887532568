import CarListing from './CarListing';
import React, { useState, useEffect } from "react";
import { db } from "./firebase";

// import { AiFillHeart } from "react-icons/ai";
// import { AiOutlineHeart } from "react-icons/ai";
// import { GoGitCompare } from "react-icons/go";

const Preview = () => {
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    const unsubscribe = db.collection('inventory').orderBy('featured', 'desc').limit(3).onSnapshot(snapshot => {
      const inventory = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInventory(inventory);
    });

    return unsubscribe;
  }, []);

  return (
    <section className="preview-container">
      {inventory.sort((a, b) => b.year - a.year).map(vehicle => (
        <CarListing vehicle={vehicle} key={vehicle.id} wideViewOnly={true}></CarListing>
      ))}
    </section>
  );
}

export default Preview;

        {/* <div className="car-listing">
          <div className="car-image-container">
            <img src="https://sereneauto.ca/wp-content/uploads/2023/09/20230831_163123-898x558.jpg"></img>
          </div>
          <div className="car-title">2015 Toyota Camry Hybrid XLE</div>
          <div className="price">
            <div className="price-container">
              <div className="new-price-title og">NEW PRICE</div>
              <div className="new-price">$17,999</div>
            </div>
            <div className="price-container">
              <div className="old-price-title">OLD PRICE</div>
              <div className="old-price">$ <s>18,999</s></div>
            </div>
            <div className="price-container">
              <div className="old-price-title">MILAGE</div>
              <div className="old-price">189,800 km</div>
            </div>
          </div>
          <div className="car-description">Clean title, fresh saftied, 5 passenger, hybrid engine, automatic transmission</div>
          <div className="car-links">
            <div className="car-link"> <AiFillHeart size={20} color="#e11d4b"/> Wishlist</div>
            <div className="car-divider">|</div>
            <div className="car-link"> <GoGitCompare size={20} color="#555555"/>Compare</div>
          </div>
          <Button className="custom-button">View Details</Button>
        </div> */}