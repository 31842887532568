import '@progress/kendo-theme-default/dist/all.css';  
import { Calendar } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect, useRef} from 'react';
import { db } from "./firebase";

// import emailjs from "@emailjs/browser";

const BookAppointment = () => {
  const [bookingTimes, setBookingTimes] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [bookingDate, setBookingDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vehicleDescription, setVehicleDescription] = useState('');
  const [date, setDate] = useState('');
  const form = useRef();
  
  const times = [
    "09:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
  ];

  useEffect(() => {    
    const unsubscribe = db.collection('appointments').onSnapshot(snapshot => {
      const appointments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(appointments);
    });

    return unsubscribe;
  }, []);

  const onDateChange = e => {
    setSelectedTimeSlot(null);
    setBookingDate(e.value);
    setDate(moment(e.value).format('L'));
  
    const selectedDate = moment(e.value);
    const dayOfWeek = selectedDate.day(); // 0 for Sunday, 6 for Saturday
    const currentTime = moment();
    const isSameDay = selectedDate.isSame(currentTime, 'day');
  
    // First, let's filter out booked slots for the selected date
    let formattedSelectedDate = moment(e.value).format('L');
    const filteredAppointments = appointments.filter(appointment => appointment.date === formattedSelectedDate);
  
    if (dayOfWeek === 0) {
      // Closed on Sundays
      setBookingTimes([]);
    } else if (dayOfWeek === 6) {
      // Saturdays from 9 am to 5 pm
      const filteredTimes = times.filter(timeSlot => {
        const startTime = parseInt(timeSlot.split(' ')[0].split(':')[0], 10);
        return startTime >= 9 && startTime <= 16;
      });
  
      // Remove booked slots from the filtered times
      filteredAppointments.forEach(appointment => {
        const duration = appointment.selectedTimeSlot;
        const index = filteredTimes.indexOf(duration);
        if (index !== -1) {
          filteredTimes.splice(index, 1);
        }
      });
  
      setBookingTimes(filteredTimes);
    } else if (isSameDay) {
      // If it's the same day, filter time slots starting after the current time
      const currentHour = currentTime.hour();
      const currentMinute = currentTime.minute();
      const currentTimeString = `${currentHour}:${currentMinute}`;
      const filteredTimes = times.filter(timeSlot => {
        const slotStart = timeSlot.split(' ')[0];
        return slotStart > currentTimeString;
      });
  
      // Remove booked slots from the filtered times
      filteredAppointments.forEach(appointment => {
        const duration = appointment.selectedTimeSlot;
        const index = filteredTimes.indexOf(duration);
        if (index !== -1) {
          filteredTimes.splice(index, 1);
        }
      });
  
      setBookingTimes(filteredTimes);
    } else {
      // Normal working hours for other days
  
      // Remove booked slots from the normal working hours
      const filteredTimes = times.slice();
  
      filteredAppointments.forEach(appointment => {
        const duration = appointment.selectedTimeSlot;
        const index = filteredTimes.indexOf(duration);
        if (index !== -1) {
          filteredTimes.splice(index, 1);
        }
      });
  
      setBookingTimes(filteredTimes);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (bookingDate !== null && selectedTimeSlot !== null){
      db.collection('appointments').add({
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        vehicleDescription,
        date,
        selectedTimeSlot
      }).then(() => {
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setPhoneNumber('');
        setVehicleDescription('');
        setDate('');
        setBookingDate(null)
        setSelectedTimeSlot(null);
        setBookingTimes([]);
      })
    }
  }

	return (
    <>
      <section className="inventory-container">
        <div className="inventory-title">Book Appointment</div>
      </section>

      <section className="car-contact">
        <div className="book-appointment-container">
          <div className="book-appointment-form">
            <div className="car-contact-title mb-2">Schedule your appointment with us!</div>
            <p className="contact-description mb-4" style={{width:"100%"}}>Whether you're ready to book your appointment or 
            have questions, we're here to assist you. Simply fill out the form with your appointment 
            details, or if you prefer to reach out directly, find the department email you'd like to 
            contact below. We look forward to serving you!</p>
            
            <form ref={form} onSubmit={handleSubmit}>
              <div className="booking-left">
                <div className="form-group" style={{gap: '10px'}}>
                  <div className="form-child">
                    <label className="form-label">First Name</label>
                    <input type="text" className="form-control mb-3" placeholder="" value={firstName} onChange={e => setFirstName(e.target.value)} required />
                  </div>
            
                  <div className="form-child">
                    <label className="form-label">Last Name</label>
                    <input type="text" className="form-control mb-3" placeholder="" value={lastName} onChange={e => setLastName(e.target.value)} required />
                  </div>
                </div> 

                <div className="form-group" style={{gap: '10px'}}>
                  <div className="form-child">
                    <label className="form-label">Email Address</label>
                    <input type="email" className="form-control mb-3" placeholder="" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required />
                  </div>

                  <div className="form-child">
                    <label className="form-label">Phone Number</label>
                    <input type="text" className="form-control mb-3" placeholder="" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required />
                  </div>   
                </div> 
            
                <label className="mb-2">Vehicle Description</label>
                <input type="text" className="form-control mb-3" placeholder="Toyota Camry 2020 Black" value={vehicleDescription} onChange={e => setVehicleDescription(e.target.value)} required />

            
                <label className="mb-2">Date</label> 
                <input type="text" className="form-control mb-3" placeholder="" value={bookingDate !== null ? moment(bookingDate).format('LL'): '--/--/----'} required disabled/>                

                <div className="form-group" style={{gap: '10px'}}>
                  <div className="form-child">
                    <label className="mb-2">From</label> 
                    <input type="time" className="form-control mb-3" placeholder="" value={selectedTimeSlot !== null ? selectedTimeSlot.split(' ')[0] : ''} required disabled/>                
                  </div>
            
                  <div className="form-child">
                    <label className="mb-2">To</label> 
                    <input type="time" className="form-control mb-3" placeholder="" value={selectedTimeSlot !== null ? selectedTimeSlot.split(' ')[2] : ''} required disabled />                
                  </div>
                </div>

                <Button type="submit" value="Send" className="custom-button show-result-fix">Book Appointment</Button>
              </div>

              <div className="booking-right">
                <div className="calander-container">
                  <div className="calander-container-child">
                    <Calendar value={bookingDate} onChange={onDateChange} min={new Date()} max={new Date(2024, 12, 31)} />

                    <div className="time-slots-container">
                      {bookingTimes.map(time => {
                        return (
                          <button key={time} className="time-slot" onClick={e => setSelectedTimeSlot(time)} type="button">{time}</button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
	);
}
  
export default BookAppointment;
