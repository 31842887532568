
import FinanceCalculator from "./FinanceCalculator";
import ContactForm from './ContactForm';

const FinancingPage = () => {
  return (
    <section className="inventory-container">
		  <div className="inventory-title">Financing</div>
      <div className="product-detail" style={{margin: '30px 0', gap: '60px'}}>
        {<FinanceCalculator priceOfCar={0}></FinanceCalculator>}
        <ContactForm/> 
      </div>
    </section>
  );
}

export default FinancingPage;