import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

const CarListing = ({vehicle, wideViewOnly}) => {
  return (
    // car-listing-grid-view
    // car-listing-wide
    <div className={`${wideViewOnly ? "car-listing-wide" : "car-listing-grid-view"}`}>
      <div className="product-img">
        {/* {vehicle.bannerImages.length > 0 ? vehicle.bannerImages.sort((a, b) => a.order - b.order)[0].carousel_Image[1] :"https://www.willow-car-sales.co.uk/wp-content/uploads/2019/11/placeholder-image-1.jpg" } */}
        <Link to={`/inventory/${vehicle.id}`}> <img src={vehicle.bannerImages.length > 0 ? vehicle.bannerImages.sort((a, b) => a.order - b.order)[0].carousel_Image[1] :"https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2" }/></Link> 
      </div>

      <div className="product-details">
        <div className="product-specs"><span className={`${vehicle.status}`}>{vehicle.status}</span></div>
        <div className="product-title"><Link to={`/inventory/${vehicle.id}`}>{vehicle.year} - {vehicle.make} {vehicle.model} {vehicle.trim}</Link></div>
        {/* <div className="product-specs">{vehicle.vehicleHighValueFeatures.slice(0, 3).join(' | ')} </div> */}
        <div className="product-price"><span className="price-listing">${parseInt(vehicle.askingPrice).toLocaleString()}</span><span className="product-taxes-and-licensing">+ Taxes</span></div>
        <div className="product-milage">Kilometers: <span className="product-milage-bold">{parseInt(vehicle.vehicleKilometers).toLocaleString()} KM</span></div>
        <div className="product-specs-lists">
          <div className="product-spec">{vehicle.vehicleCondition == 'Used' ? `Certified Used` : vehicle.vehicleCondition}</div>
          {/* <div className="product-spec">{vehicle.uploadDate}</div> */}
          <div className="product-spec">{vehicle.bodyType}</div>
          <div className="product-spec">{vehicle.transmission}</div>
          <div className="product-spec">{vehicle.driveType}</div>
        </div>

        <div className="product-tier-container hide-product-detail">
          {vehicle.carFaxPDFUrl && 
            <div className="product-carfax">
              <Link to={`${vehicle.carFaxPDFUrl}`} target="_blank">
              <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FCarFaxLogo_1000x1000.png?alt=media&token=a6638f18-bc80-436b-a379-6901b1d99826"/>
            </Link>
          </div>}

          {vehicle.reportedAccidents && 
              <div className="product-accident-free">
              <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FNoReportedAccidents_1000x1000.png?alt=media&token=ad7b70c1-3257-4a60-b3af-b59d62fa1394"/>
            </div>}

          {vehicle.lowKilometers && 
            <div className="product-low-kilometers">
              <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FLowKilometers_1000x1000.png?alt=media&token=d23a53e2-6a75-4dd5-b5c7-f7ff913c90c2"/>
            </div>}

          {vehicle.topCondition && 
            <div className="product-top-condition">
              <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FTopCondition_1000x1000.png?alt=media&token=95e668b3-8701-4d55-b05a-2426e949990f"/>
            </div>}

          {vehicle.oneOwner && 
            <div className="product-one-owner">
              <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FOneOwner_1000x1000.png?alt=media&token=aeecda7e-5f4c-454d-aef8-0048b88b70fb"/>
            </div>}
        </div>
      </div>

      <div className="product-report">
        <div className="product-report-buttons">
          <Button className="custom-button"><Link to={`/inventory/${vehicle.id}`}>View more details</Link></Button>
          <Button className="custom-reset-button"><Link to={`/financing`}>Finance vehicle</Link></Button>
          {/* {vehicle.carFaxPDFUrl &&
            <Button className="custom-carFax-button">
              <Link to={`${vehicle.carFaxPDFUrl}`} target="_blank">
                 <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FCarFaxLogo_1000x1000.png?alt=media&token=a6638f18-bc80-436b-a379-6901b1d99826" />
              </Link>
            </Button>
          } */}
          
          {/* {vehicle.carFaxPDFUrl && <Button className="custom-outline-button"><Link to={`${vehicle.carFaxPDFUrl}`} target="_blank">CarFax® Report</Link></Button>} */}
          {/* {vehicle.carFaxPDFUrl && 
            <Button className="custom-carFax-button">
              <Link to={`${vehicle.carFaxPDFUrl}`} target="_blank">
                <div>View History Report </div>
              </Link>
            </Button>
          } */}
        </div>
        
        <div className="product-tier-container">
          <div className="product-tier-container">
            {vehicle.carFaxPDFUrl && 
              <div className="product-carfax">
                <Link to={`${vehicle.carFaxPDFUrl}`} target="_blank">
                <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FCarFaxLogo_1000x1000.png?alt=media&token=a6638f18-bc80-436b-a379-6901b1d99826"/>
              </Link>
            </div>}

            {vehicle.reportedAccidents && 
                <div className="product-accident-free">
                <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FNoReportedAccidents_1000x1000.png?alt=media&token=ad7b70c1-3257-4a60-b3af-b59d62fa1394"/>
              </div>}

            {vehicle.lowKilometers && 
              <div className="product-low-kilometers">
                <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FLowKilometers_1000x1000.png?alt=media&token=d23a53e2-6a75-4dd5-b5c7-f7ff913c90c2"/>
              </div>}

            {vehicle.topCondition && 
              <div className="product-top-condition">
                <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FTopCondition_1000x1000.png?alt=media&token=95e668b3-8701-4d55-b05a-2426e949990f"/>
              </div>}

            {vehicle.oneOwner && 
              <div className="product-one-owner">
                <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FOneOwner_1000x1000.png?alt=media&token=aeecda7e-5f4c-454d-aef8-0048b88b70fb"/>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default CarListing;