import React from "react";
import { Link } from "react-router-dom";
import { db } from './firebase';

const WarrantyOption = ({warranty}) => {
  function handleRemove(id) {
    db.collection('Warranty').doc(id).delete()
  }

  return (
    <div className="warranty-option">
      <div className="">{warranty.planType}</div>
      <div className="">{warranty.duration}</div>
      <div className="">{warranty.mileage}</div>
      <div className="">{warranty.maxClaim}</div>
      <div className="">${warranty.price}</div>
      <Link to={`edit/${warranty.id}`}>Edit</Link>
      {/* <div className="">{warranty.uploadDate}</div> */}
      {/* <div style={{color:'red', cursor: 'pointer'}}onClick={() => handleRemove(warranty.id)} variant="outline-danger">Remove</div> */}
    </div>
  );
}

export default WarrantyOption;