import { useCallback, useEffect, useState } from "react";

import { GoDotFill } from "react-icons/go";

import './stepper.css'
import SpecSheet from "./SpecSheet";
import { TextBox } from "@progress/kendo-react-inputs";
import AddListing from "../AddListing";
import { MapVinInfo } from "./FeatureMap";
import { isValidVin } from "./vin-validate";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import GatheringData from "./GatherData";

const defaultStepState = { vin: '', res: {}, features: {}, docId: '' };
const FeatureContainer = () => {
    const [state, setState] = useState(defaultStepState);
    const [step, setStep] = useState({ current: 0, complete: 0 });

    const goNext = useCallback((data = {}) => {
        console.log(data);

        const newState = {
            ...state,
            ...(step.current >= 0 && { res: {} }),
            ...(step.current >= 1 && { features: {} }),
            ...data
        }
        setState(newState);

        // step.complete = step.current += 1;
        // step.current += 1;
        // if (index >= step.complete)
        //     step.complete = index
        setStep({ complete: step.current + 1, current: step.current + 1 });

    }, [state, step]);

    const reset = () => {
        setState(defaultStepState);
        setStep({ current: 0, complete: 0 });
    }

    const changeStep = useCallback(index => {
        if (index <= step.complete && step.complete != 4)
            setStep({ complete: step.complete, current: index });
    }, [step]);
    return (
        <div className="container">
            <div className="stepper">
                <div className="stepper__steps mb-4">
                    <Step title='Enter VIN' index={0} step={step} onClick={e => changeStep(0)}>
                        <EnterVIN vin={state.vin} onSubmit={vin => goNext({ vin })} />
                    </Step>

                    <Step title='Gathering Data' index={1} step={step} onClick={e => step.complete == 1 && changeStep(1)}>
                        <GatheringData vin={state.vin} res={state.res} onSubmit={res => goNext({ res })} />
                    </Step>

                    <Step title='Select Features' index={2} step={step} onClick={e => changeStep(2)}>
                        <SpecSheet vin={state.vin} editMode={true}
                            data={!_.isEmpty(state.res) ? MapVinInfo(state.res) : state.features}
                            onSubmit={features => goNext({ features })} />
                    </Step>

                    <Step title='Fill Form' index={3} step={step} onClick={e => changeStep(3)}>
                        <AddListing vin={state.vin} defaultFeatures={state.features} onSubmit={docId => goNext({ docId })} />
                    </Step>
                    <Step title='Done' index={4} step={step} onClick={e => changeStep(4)}>
                        <div className="d-flex flex-row justify-content-center">
                            <Link to={'/inventory/' + state.docId} target="_blank" rel="noopener noreferrer" >
                                <Button className="primary">
                                    View Vehicle Page
                                </Button>
                            </Link>
                            <Button variant="secondary" className="ms-1" onClick={reset}>Decode Next VIN</Button>
                        </div>
                    </Step>
                </div>
            </div>


            {/* <div className="">
                <div className="row">

                    <Stepper onChange={handleChange} items={steps} orientation="vertical"/>
                </div>
                <div className="row data"></div>
            </div> */}
        </div>
    );
}

export default FeatureContainer;


const EnterVIN = ({ onSubmit, vin }) => {
    const [_vin, setVin] = useState(vin)
    const [error, setError] = useState(null)
    const handleChange = e => {
        const val = e.target.value;
        if (!isValidVin(val))
            setError('(invalid VIN)');
        else setError(null);
        setVin(val)
    }
    return (
        <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column">

                <div>VIN:
                    <small className="vin-error">{error}</small>
                </div>
                <TextBox
                    value={_vin}
                    valid={!Boolean(error)}
                    onChange={handleChange}
                    placeholder="VIN number"
                    size='large'
                />
                <Button
                    className="mt-2"
                    variant="primary"
                    type={"submit"}
                    onClick={e => _vin && !error && onSubmit(_vin)}
                // disabled={!formRenderProps.allowSubmit}
                >Decode VIN</Button>
            </div>
        </div>
    );
}

const icons = {
    'is-finished': '✅︎',
    'is-current': <GoDotFill color='mediumblue' />,
}

function Step({ title, children, step, index, onClick }) {
    // console.log(step, index);
    // debugger;
    const statusClass = index > step.complete ? 'is-disabled' : index < step.complete ? 'is-finished' : 'is-current';

    return <div className={"step " + statusClass}>
        <div className="step__status" onClick={onClick}>
            <div className="step__status__indicator">
                <div className="step__status__line__wrapper">
                    <div className="step__status__line"></div>
                </div>
                <div className="step__status__dot">
                    {icons[statusClass]}
                </div>
                <div className="step__status__line__wrapper">
                    <div className="step__status__line"></div>
                </div>
            </div>
            <a className="step__status__label">{title}</a>
        </div>
        {children &&
            < div className="step__flyout__container">
                <div className="step__status__line__wrapper">
                    <div className="step__status__line"></div>
                </div>
                <div className="step__flyout">
                    <div className="step__flyout__content">
                        {step.current == index && children}
                    </div>
                    {/* <div className="step__flyout__footer">
                        <button className="button button--secondary">
                            <span className="iconUxt arrowSingleLeft" aria-hidden="true"></span>Back
                        </button>
                        <button className="button button--secondary">Cancel</button>
                        <div className="footer__buttonRight">
                            <button className="button button--primary">Next
                                <span className="iconUxt arrowSingleRight" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        }
    </div>
}
