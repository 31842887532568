import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = () => {  
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  
  return (
    <nav>
      <div className="nav-toggle">
        <div className={`${isToggled ? "toggle-brand brand-show" : "toggle-brand"}`}>
          <Link to={`/`}>SERENE<span className="car-highlight"> AUTO</span></Link>
          </div>
        <div className="toggle-button" onClick={handleToggle}>
          <AiOutlineMenu size={20} color="rgba(0, 0, 0, 0.300)"/>
        </div>  
      </div>
      
      <ul className={`${isToggled ? "car-navbar show" : "car-navbar"}`}>
        <li className="nav-icon"><Link to={`/`}>SERENE<span className="car-highlight"> AUTO</span></Link></li>
        <li className="nav-item"><Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: '', availabilityFilter: ''}))}>Inventory</Link></li>
        {/* <li className="nav-item"><Link to={`/services`}>Services</Link></li> */}
        <li className="nav-item"><Link to={`/about`}>About us</Link></li>
        <li className="nav-item"><Link to={`/financing`}>Financing</Link></li>
        <li className="nav-item"><Link to={`/appointment`}>Book Appointment</Link></li>

        {/* <li className="nav-item"><Link to={`/contact`}>Contact Us</Link></li> */}

        <li className="contact-button"><Button className="custom-button show-result-fix"><Link to={`/contact`}>Contact Us</Link></Button></li>
      </ul>
    </nav>
  );
}

export default Header;