import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const ShippingCalculator = () => {
  const [fromCity, setFromCity] = useState('Winnipeg');
  const [toCity, setToCity] = useState('');
  const [shippingCost, setShippingCost] = useState(null);
  const [canadianCities, setCanadianCities] = useState([
    'Toronto',
    'Thunderbay',
    'Edmonton',
    'Ottawa',
    'Montreal',
    'Quebec',
    'Vancouver',
    'Brampton',
    'Calgary',
    'Hamilton',
    'Mississauga',
    'Halifax',
    'British Columbia',
    'Charlottetown',
    'Fredericton',
    'Regina',
  ]);

  const calculateShipment = () => {
    // Function to calculate shipping cost based on the postal codes entered
    // You can replace this logic with your actual shipping cost calculation logic

    // For example, considering only distances between cities as previously shown
    const cityDistances = {
      Winnipeg: {
        Edmonton: 1300, // Distance in kilometers
        Calgary: 1400,
        Thunderbay: 700,
        Toronto: 2100,
        Ottawa: 2017,
        Montreal: 2113,
        Quebec: 2438,
        Vancouver: 2236,
        Brampton: 1830,
        Calgary: 1377,
        Hamilton: 1803,
        Mississauga: 1829,
        Halifax: 2820,
        'British Columbia': 2277,
        Charlottetown: 2911,
        Fredericton: 2515,
        Regina: 573,
        // ... Add other cities and their distances as needed
      },
    };

    const origin = 'Winnipeg'; // Assuming shipping is always from Winnipeg
    const destination = toCity; // Use the entered destination postal code

    const distance = cityDistances[origin][destination];

    if (distance) {
      const costPerKilometer = 0.1; // Replace this with the actual shipping cost per kilometer
      const shippingCost = distance * costPerKilometer;
      setShippingCost(`Estimated shipping cost from ${origin} to ${destination}: $${shippingCost.toFixed(0)}`);
    } else {
      setShippingCost('');
    }
  };

  return (
    
    <div className="product-financing-calculator">
      <div className="vehicle-title">Estimate shipping</div>

      <div className="form-group" style={{gap: '10px'}}>
        <div className="form-floating form-child" style={{width: '100%'}}>
          <input type="text" className="form-control" value={fromCity} onChange={(e) => setFromCity(e.target.value)} disabled/>
          <label>From</label>
        </div>      
        
        <div className="form-floating form-child"  style={{width: '100%'}}>
          <select
            aria-label="Select Destination City"
            className="form-select"
            value={toCity}
            onChange={(e) => setToCity(e.target.value)}
          >
            <option value="">Select a city</option>
            {canadianCities.map((city, index) => (
              <option key={index} value={city}>{city}</option>
            ))}
          </select>
          <label>Destination City</label>
        </div>
      </div>

      <Button className="custom-reset-button financing-button" onClick={calculateShipment}>Calculate Shipping</Button>

      {shippingCost && <div className="shipping-cost">{shippingCost}</div>}

      <div className="financing-disclaimer">
        *The estimated shipping costs provided are for informational purposes only and should be considered as rough estimates.
      </div>
    </div>
  );
};

export default ShippingCalculator;