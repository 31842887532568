import { useEffect, useState } from "react";
import main from "./FeatureService";
import { MdError } from "react-icons/md";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Button, Card } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Switch } from "@progress/kendo-react-inputs";
import _ from "lodash";
import { querySelectorDeep } from "query-selector-shadow-dom";

const GatheringData = ({ onSubmit, vin }) => {
    const [res, setRes] = useState({
        nhtsa: {},
        kbb: { trims: [] },
        faxvin: {
            trims: { items: [], selected: '' }
        },
        adesa: {}
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState({ kbb: null, faxvin: null });
    const [usable, setUsable] = useState({ kbb: true, faxvin: true, nhtsa: false, adesa: false });

    const handleClick = e => {
        //load data and submit merge with res
        // res &&
        async function load() {

            let params = {};
            if (selected.faxvin && res.faxvin.trims.selected != selected.faxvin)
                _.set(params, 'faxvin.trim', selected.faxvin);
            if (selected.kbb)
                _.set(params, 'kbb.trim', selected.kbb);

            const data = await getData(vin, params);
            if (error)
                return;

            const result = _.pick(data, Object.keys(usable).filter(x => usable[x]));

            //when nhtsa data exists but use switch set to false
            if (data.nhtsa && !result.nhtsa)
                result.nhtsa = { Doors: data.nhtsa.Doors };

            onSubmit(result);
        }

        load();
    }

    const uploadFile = event => {
        setUsable({ ...usable, adesa: false });


        let file = event.target.files[0], doc;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            // console.log(fileReader);
            try {
                if (Document.parseHTMLUnsafe)
                    doc = Document.parseHTMLUnsafe(fileReader.result);
                else {

                    let parser = new DOMParser();
                    doc = parser.parseFromString(fileReader.result, "text/html", {
                        includeShadowRoots: true
                    });
                }

            } catch (error) {
                console.log(error);
                setRes({ ...res, adesa: { error: 'error on parsing html file' } });
                return;
            }

            let base = {}, specsInfo = {}, equipment = {}, highValue = {};


            try {
                // root = doc.querySelector('ol-condition-report,ol-widget-shell');

                const baseElem = querySelectorDeep('ol-page-title .stockwave-vehicle-info.stockwave-vehicle-details', doc.body);
                base = JSON.parse(baseElem.innerText);

                // if (base.vin != vin) {
                //     setRes({ ...res, adesa: { error: `VIN is not matched` } });
                //     return;
                // }

                const trimElem = querySelectorDeep('[year][make][model][series]', doc.body);
                if (trimElem) {
                    const { year, make, model, series } = trimElem.attributes;
                    if (year) specsInfo.Year = year.nodeValue;
                    if (make) specsInfo.Make = make.nodeValue;
                    if (model) specsInfo.Model = model.nodeValue;
                    if (series) specsInfo.Trim = series.nodeValue;
                }

                if (base.odometerUnitOfMeasure == 'miles')
                    base.odometer *= 1.60934;

                specsInfo.odometer = base.odometer;

            } catch (error) {
                console.log(error);
                setRes({ ...res, adesa: { error: `html is not valid!` } });
                return
            }



            try {
                const specs = querySelectorDeep('ol-vehicle-data ol-original-data ol-original-vehicle-info div.vehicle-info', doc.body);

                for (let i = 0; i < specs.childNodes.length; i += 2) {
                    const item = specs.childNodes[i];
                    const label = item.innerText.trim();

                    if (!['Inventory Type', 'Odometer Digits', 'Title State'].includes(label))
                        specsInfo[label] = specs.childNodes[i + 1].innerText.trim()

                    if (label.toLowerCase().includes('color'))
                        specsInfo[label] = cap(specsInfo[label])
                }

            } catch (error) {
                console.log(error);
            }



            try {
                const highValueElem = querySelectorDeep('ol-vehicle-data ol-original-data ol-original-data-high-value-options ol-original-data-generic-list div.generic-list>div:not([class*=unavailable])', doc.body);
                highValue = {};
                highValueElem.childNodes.forEach(el => highValue[cap(el.innerText.trim())] = 'Yes');


            } catch (error) {
                console.log(error);
            }


            try {
                const equipmentElem = querySelectorDeep('ol-vehicle-data ol-original-data ol-original-data-equipment ol-original-data-generic-list div.generic-list>div', doc.body);
                equipment = {};
                equipmentElem.childNodes.forEach(el => equipment[cap(el.innerText.replaceAll('YES', '').trim())] = 'Yes');
            } catch (error) {
                console.log(error);
            }

            // res.adesa = { base, specs: specsInfo, equipment, highValue };
            setRes({ ...res, adesa: { base, specs: specsInfo, equipment, highValue } });

            setUsable({ ...usable, adesa: true });

        }
        if (file)
            fileReader.readAsText(file);


    }
    const convertKBBTrims = styles => {
        const trims = [];
        for (let i = 0; i < styles.length; i++) {
            const body = styles[i];

            for (let j = 0; j < body.trims.length; j++) {
                let { vehicleId, name } = body.trims[j];

                if (styles.length > 1)
                    name = body.name + ' - ' + name;

                trims.push({ value: vehicleId, name })
            }
        }

        return trims;
    }

    async function getData(vin, params) {
        setLoading(true);
        setError(null);

        let data = {};
        try {
            data = await main({ vin, params });

        }
        catch (error) {
            setError(error.message);
            setLoading(false)
            return data;
        }

        if (data.kbb && !data.kbb.error && data.kbb.basic.styles) {
            data.kbb.basic.trims = convertKBBTrims(data.kbb.basic.styles);
            setSelected({ ...selected, kbb: data.kbb.basic.trims[0].value })
            if (data.kbb.main)
                data.kbb.main.trim = data.kbb.basic.trims.find(o => o.value == selected.kbb).name;
        }

        setUsable({
            kbb: params.kbb ? !data.kbb.error : usable.kbb,
            faxvin: params.faxvin ? !data.faxvin.error && data.faxvin.Basic : usable.faxvin,
            nhtsa: false
        });
        const result = { ...res, ...data };
        setRes(result);
        setLoading(false);

        return result;
    }
    useEffect(() => {

        getData(vin, { faxvin: {}, nhtsa: {}, kbb: {} });

    }, [vin]);


    function Info({ make, model, year, trim }) {
        return <div className="d-flex flex-column">
            <div className="d-flex flex-row">
                <div className="semi-bold">Make:</div>
                <div className="ps-1 text-truncate" title={make}>{make} </div>
            </div>
            <div className="d-flex flex-row">
                <div className="semi-bold">Model:</div>
                <div className="ps-1 text-truncate" title={model}>{model} </div>
            </div>
            <div className="d-flex flex-row">
                <div className="semi-bold">Year:</div>
                <div className="ps-1 text-truncate" title={year}>{year} </div>
            </div>
            <div className="d-flex flex-row">
                <div className="semi-bold">Trim:</div>
                <div className="ps-1 text-truncate" title={trim}>{trim} </div>
            </div>
        </div>;
    }

    const Icon = ({ error, qs, ok }) => {
        if (error)
            return <MdError color='red' size='23' />
        else if (qs)
            return <BsFillQuestionCircleFill color="yellow" size='23' />
        else if (ok)
            return <IoMdCheckmarkCircle color='green' size='23' />
    }
    const Error = ({ msg }) => {
        return <div className="d-flex flex-column align-items-center ">
            <MdError color='red' size='60' />
            <span className="error">{msg}</span>
        </div>
    }


    if (loading)
        return <div className="spinner-container mx-auto my-5">
            <div role="status" className="spinner-border spinner-styling">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    else if (error)
        return <div className="row gather-data bold">
            <Error msg={error} />
        </div>
    return (
        <div className="d-flex flex-column gather-data">

            <div className="d-flex flex-row justify-content-center flex-wrap">
                {/* {errors.nhtsa && */}
                {/* } */}

                <Card className="kbb mx-2" style={{ width: '200px' }}>
                    <CardHeader className="text-center bold p-0 py-2">
                        <div className="d-flex flex-row">
                            <Icon error={res.kbb.error} ok={!res.kbb.error} />
                            <span className="bold ps-1">KBB</span>
                            <Switch className="ms-auto" onLabel="Use" offLabel="" size='small' checked={usable.kbb} disabled={res.kbb.error}
                                onChange={e => setUsable({ ...usable, kbb: e.target.value })} />
                        </div>
                    </CardHeader>
                    <Card.Body>
                        <div className="d-flex flex-column">
                            {res.kbb.error ? <Error msg='error on getting data from kbb' />
                                :
                                <Info make={res.kbb.basic.make} model={res.kbb.basic.model} year={res.kbb.basic.year} trim={
                                    <select label="Trims" value={selected.kbb} onChange={e => setSelected({ ...selected, kbb: e.target.value })} >
                                        {res.kbb.basic.trims.map(item =>
                                            <option value={item.value}>{item.name}</option>
                                        )}
                                    </select>} />
                            }
                        </div>
                    </Card.Body>
                </Card>
                <Card className="faxvin mx-2" style={{ width: '200px' }}>
                    <CardHeader className="text-center bold p-0 py-2">
                        <div className="d-flex flex-row">
                            <Icon error={res.faxvin.error} ok={!res.faxvin.error} />
                            <span className="bold ps-1">FAXVIN</span>
                            <Switch className="ms-auto" onLabel="Use" offLabel="" size='small' checked={usable.faxvin} disabled={res.faxvin.error}
                                onChange={e => setUsable({ ...usable, faxvin: e.target.value })} />
                        </div>
                    </CardHeader>
                    <Card.Body>
                        <div className="d-flex flex-column">
                            {!res.faxvin || !res.faxvin.Basic || res.faxvin.error ? <Error msg='error on getting data from faxvin' />
                                :
                                <Info make={res.faxvin.Basic.Make} model={res.faxvin.Basic.Model} year={res.faxvin.Basic['Model Year']}
                                    trim={res.faxvin.trims.selected ?
                                        <select label="Trims" value={selected.faxvin || res.faxvin.trims.selected} onChange={e => setSelected({ ...selected, faxvin: e.target.value })} >
                                            {res.faxvin.trims.items.map(item => <option value={item.value}>{item.text}</option>)}
                                        </select>
                                        :
                                        res.faxvin.Basic['Trim Level']
                                    } />
                            }

                        </div>
                    </Card.Body>
                </Card>
                <Card className="nhtsa mx-2" style={{ width: '200px' }}>
                    <CardHeader className="text-center bold p-0 py-2">
                        <div className="d-flex flex-row">
                            <Icon error={res.nhtsa.error} ok={!res.nhtsa.error} />
                            <span className="bold ps-1">NHTSA</span>
                            <Switch className="ms-auto" onLabel="Use" offLabel="" size='small' disabled={res.nhtsa.error} checked={usable.nhtsa}
                                onChange={e => setUsable({ ...usable, nhtsa: e.target.value })} />
                        </div>
                    </CardHeader>
                    <Card.Body>
                        <div className="d-flex flex-column">
                            {res.nhtsa.error ? <Error msg='error on getting data from nhtsa' />
                                :
                                <Info make={res.nhtsa.Make} model={res.nhtsa.Model} year={res.nhtsa.ModelYear} trim={res.nhtsa.Trim} />
                            }
                        </div>
                    </Card.Body>
                </Card>
                <Card className="nhtsa mx-2" style={{ width: '200px' }}>
                    <CardHeader className="text-center bold p-0 py-2">
                        <div className="d-flex flex-row">
                            <Icon error={res.adesa.error} ok={!res.adesa.error} />
                            <span className="bold ps-1">ADESA</span>
                            <Switch className="ms-auto" onLabel="Use" offLabel="" size='small' disabled={res.adesa.error} checked={usable.adesa}
                                onChange={e => setUsable({ ...usable, adesa: e.target.value })} />
                        </div>
                    </CardHeader>
                    <Card.Body>
                        <div className="d-flex flex-column">
                            {res.adesa.error ? <Error msg={res.adesa.error} />
                                :
                                res.adesa.base &&
                                <Info make={res.adesa.base.make} model={res.adesa.base.model} year={res.adesa.base.year} />
                            }
                            <input type="file"
                                name="myFile"
                                className="form-control form-control-sm"
                                accept=".htm ,.html "
                                onChange={uploadFile} />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Button
                className="mt-2 col-12 col-sm-3 m-auto mt-2"
                variant="primary"
                type={"submit"}
                onClick={handleClick}
            // disabled={!formRenderProps.allowSubmit}
            >Get Data</Button>
        </div>
    );

}


const cap = val => {
    let newVal = val.toLowerCase();

    if (/[0-9]+wd|\\/.test(newVal) || newVal.includes('/'))
        return val;

    return newVal.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
}


export default GatheringData;
