import { db } from './firebase';
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams, Navigate} from 'react-router-dom';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree'; // Import other step forms
import StepFour from './StepFour'; // Import other step forms

const Warranty = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicleDetails, setVehicleDetails] = useState({});

  useEffect(() => {
    const unsubscribe = db.collection('inventory').doc(id).onSnapshot(doc => {
      if (!doc.exists) {
        console.log('No results Found');
      } else {
        setVehicleDetails(doc.data());
      }
    });

    return unsubscribe;
  }, [id]);

  return (
    <>
      {/* <section className="inventory-container">
        <div className="inventory-title">Warranty</div>
      </section> */}

      <section className="inventory-container">
        <Routes>
          {/* <Route path="/" element={<div>WELCOME</div>}/> */}
          <Route path="/vehicle" element={<StepOne vehicleDetails={vehicleDetails} /> } />
          <Route path="/programs" element={<StepTwo /> } />
          <Route path="/customer" element={<StepThree />} />
          <Route path="/summary" element={<StepFour />} />
        </Routes>
      </section>
    </>
  );
};

export default Warranty;
