import React, { useState, useEffect } from 'react';
import { db } from "./firebase";
import { useParams, Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import FinanceCalculator from './FinanceCalculator';
import ShippingCalculator from './ShippingCalculator';

import { IoSpeedometerOutline } from "react-icons/io5";
import { PiEngine } from "react-icons/pi";
import { GiGearStickPattern } from "react-icons/gi";
import { BsFuelPump } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { GiCarSeat } from "react-icons/gi";
import { LiaOilCanSolid } from "react-icons/lia";
import { GiCartwheel } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegGem } from "react-icons/fa";

import SpecSheet from './features/SpecSheet';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from 'lodash';
import Sticky from 'react-sticky-el';

const nf = new Intl.NumberFormat('en-US');
const vehicleDataProps = ['Year', 'odometer', 'Body Style', 'Doors', 'Drivetrain', 'Engine', 'Exterior Color', 'Fuel Type', 'Interior Color', 'Make', 'Model', 'Registration Province', 'Transmission', 'Trim']

const CarDetail = ({ currentUser, id }) => {
  // const { id } = useParams();
  const [vehicleDetail, setVehicleDetail] = useState();
  const [carPrice, setCarPrice] = useState(0);
  const [galleryHighlight, setGalleryHighlight] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState('1');
  const [collapsed, setCollapsed] = React.useState(true);
  const [displayVehicleDataSection, setDisplayVehicleDataSection] = React.useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.body.scrollTop = 0; // For Safari

    const unsubscribe = db.collection('inventory').doc(id).onSnapshot(doc => {
      let data = doc.data()
      setVehicleDetail(data);

      if (_.isEmpty(data.features))
        setSelectedTab('2');

      if (!_.isEmpty(data.features) && (!_.isEmpty(data.features['Vehicle Data']) || !_.isEmpty(data.features['High Value']) || !_.isEmpty(data.features.Equipment))) {
        console.log(data.features['High Value'])
        setDisplayVehicleDataSection(true);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (vehicleDetail && vehicleDetail.askingPrice) {
      setCarPrice(parseInt(vehicleDetail.askingPrice));
    }
  }, [vehicleDetail]);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        // size="xl"
        centered
        size="xl">
        <Modal.Body className="img-modal">
          {vehicleDetail.bannerImages.length > 0 && <>
            {<IoIosArrowBack className='left-svg' size={50} onClick={() => setGalleryHighlight(galleryHighlight == 0 ? vehicleDetail.bannerImages.length - 1 : galleryHighlight - 1)} />}
            <img src={vehicleDetail.bannerImages.sort((a, b) => a.order - b.order)[galleryHighlight].carousel_Image[2]}></img>
            {<IoIosArrowForward className='right-svg' size={50}
              onClick={() => setGalleryHighlight(galleryHighlight + 1 == vehicleDetail.bannerImages.length ? 0 : galleryHighlight + 1)} />}
          </>}
        </Modal.Body>
      </Modal>
    );
  }

  function scrollToElement(elementId) {
    document.querySelector(`#${elementId}`).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }


  return (
    <React.Fragment>
      {vehicleDetail ?
        <div className="car-detail-container">
          <Sticky>
            <div className="car-detail-header" style={{ backgroundColor: '#fff' }}>
              <div>
                <div className="detail-title">
                  {vehicleDetail.year} - {vehicleDetail.make} {vehicleDetail.model} {vehicleDetail.trim} <span className="detail-title-milage">({parseInt(vehicleDetail.vehicleKilometers).toLocaleString()} KM)</span></div>
                <div className="detail-specs">
                  {vehicleDetail.vehicleHighValueFeatures && vehicleDetail.vehicleHighValueFeatures.length > 0 && vehicleDetail.vehicleHighValueFeatures.slice(0, 4).join(' | ')}
                  {vehicleDetail.titleHighValue && vehicleDetail.titleHighValue.length > 0 && vehicleDetail.titleHighValue.toString().replaceAll(',', ' | ')}
                </div>
                <div className="product-detail-price">
                  <span className="financing-price">${parseInt(vehicleDetail.askingPrice).toLocaleString()}</span>
                  <span className="financing-taxes">+ taxes</span>
                </div>
                <div className='navigation-buttons'>
                  <ul>
                    {displayVehicleDataSection &&
                      <li onClick={() => scrollToElement('vehicle-data-section')}>Vehicle Data</li>
                    }
                    <li onClick={() => scrollToElement('detailed-features-section')}>Detailed Features</li>
                    <li onClick={() => scrollToElement('warranty-section')}>Warranty</li>
                    <li>
                      {vehicleDetail.carFaxPDFUrl.length > 0 &&
                        <Link to={`${vehicleDetail.carFaxPDFUrl}`} target="_blank" title='Veiw Carfax'>
                          Carfax
                        </Link>
                      }
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                {currentUser ? <Button className="custom-outline-button" style={{ width: '100%' }}><Link to={`/addListing/${id}`}>Edit Listing</Link></Button> : ''}
                <div className="VIN-number mt-2"><span className="VIN-listing-bolded">VIN:</span> {vehicleDetail.VIN}</div>
                <div className="listing-number"><span className="VIN-listing-bolded">Stock #:</span> {'SE' + String(vehicleDetail.stockNumber).padStart(4, '0')}</div>
                {/* <Button variant="outline-secondary"><Link to={`/edit/${id}`}>Edit</Link></Button> */}
              </div>
            </div>
          </Sticky>

          <div className="product-detail">
            <div className="detail-gallery">
              {vehicleDetail.bannerImages.length > 0 ?
                <>
                  <div className="tab-highlighted">
                    <div className="gallery-tab">
                      <div className={`product-status ${vehicleDetail.status.toLowerCase()}`}>{vehicleDetail.status}</div>
                      {<IoIosArrowBack className='left-svg' size={50} onClick={() => setGalleryHighlight(galleryHighlight == 0 ? vehicleDetail.bannerImages.length - 1 : galleryHighlight - 1)} />}
                      {<img onClick={() => setModalShow(true)} src={vehicleDetail.bannerImages.sort((a, b) => a.order - b.order)[galleryHighlight].carousel_Image[2]} />}
                      {<IoIosArrowForward className='right-svg' size={50}
                        onClick={() => setGalleryHighlight(galleryHighlight + 1 == vehicleDetail.bannerImages.length ? 0 : galleryHighlight + 1)} />}
                    </div>
                  </div>
                  <div className="tab-navigation" >
                    {vehicleDetail.bannerImages
                      .slice()
                      .sort((a, b) => a.order - b.order)
                      .map((banner, index) => (
                        <div className={`${galleryHighlight.id == banner.id ? 'gallery-tab active-tab' : 'gallery-tab'}`} key={banner.id} onClick={() => setGalleryHighlight(index)}>
                          <img src={`${banner.carousel_Image[2]}`} />
                        </div>
                      ))}
                  </div>
                </>
                :
                <>
                  <div className="tab-highlighted">
                    <div className="gallery-tab">
                      <div className={`product-status ${vehicleDetail.status.toLowerCase()}`}>{vehicleDetail.status}</div>
                      {<img src='https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2' />}
                    </div>
                  </div>
                  <div className="tab-navigation" >
                    <div className='gallery-tab gallery-tab'>
                      <img src='https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2' />
                    </div>
                    <div className='gallery-tab gallery-tab'>
                      <img src='https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2' />
                    </div>
                    <div className='gallery-tab gallery-tab'>
                      <img src='https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2' />
                    </div>
                    <div className='gallery-tab gallery-tab'>
                      <img src='https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2Fdownload_1000x1000.jpg?alt=media&token=0c1e8bdd-e89a-4c38-b384-2d92f89b6ef2' />
                    </div>
                  </div>
                </>}
            </div>

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              // backdrop="static"
              animation={false}
              keyboard={true}
            />

            <div className="detail-specifications">
              <div className="product-details">
                <div className="vehicle-overview">
                  <div className="vehicle-title">Vehicle Overview</div>
                  <div className="vehicle-overview-specs">
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Make:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.make}</div>
                    </div>
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Model:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.model}</div>
                    </div>
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Trim:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.trim}</div>
                    </div>
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Year:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.year}</div>
                    </div>
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Interior Colour:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.interiorColour}</div>
                    </div>
                    <div className="vehicle-overview-spec">
                      <div className="vehicle-overview-title">Exterior Colour:</div>
                      <div className="vehicle-overview-detail">{vehicleDetail.exteriorColour}</div>
                    </div>
                  </div>
                </div>

                <div className="vehicle-details">
                  <div className="vehicle-title">Vehicle Details</div>
                  <div className="vehicle-specs">
                    {/* <div className="vehicle-spec">
                      <LiaOilCanSolid size={25} />
                      <div>
                        <div className="vehicle-spec-title">Condition</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.vehicleCondition}</div>
                      </div>
                    </div> */}
                    <div className="vehicle-spec">
                      <IoSpeedometerOutline size={25} />
                      <div>
                        <div className="vehicle-spec-title">Kilometers</div>
                        <div className="vehicle-spec-detail">{parseInt(vehicleDetail.vehicleKilometers).toLocaleString()} KM</div>
                      </div>
                    </div>
                    <div className="vehicle-spec">
                      <GiGearStickPattern size={25} />
                      <div>
                        <div className="vehicle-spec-title">Transmission</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.transmission}</div>
                      </div>
                    </div>
                    <div className="vehicle-spec">
                      <PiEngine size={25} />
                      <div>
                        <div className="vehicle-spec-title">Engine</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.engineType}</div>
                      </div>
                    </div>
                    <div className="vehicle-spec">
                      <GiCartwheel size={25} />
                      <div>
                        <div className="vehicle-spec-title">Drivetrain</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.driveType}</div>
                      </div>
                    </div>
                    {vehicleDetail.fuelEconomy &&
                      <div className="vehicle-spec">
                        <BsFuelPump size={25} />
                        <div>
                          <div className="vehicle-spec-title">Fuel Economy</div>
                          <div className="vehicle-spec-detail">{vehicleDetail.fuelEconomy}</div>
                        </div>
                      </div>
                    }
                    {vehicleDetail.fuelEconomyCity &&
                      <div className="vehicle-spec">
                        <BsFuelPump size={25} />
                        <div>
                          <div className="vehicle-spec-title">Fuel Economy City</div>
                          <div className="vehicle-spec-detail">{vehicleDetail.fuelEconomyCity}</div>
                        </div>
                      </div>
                    }
                    {vehicleDetail.fuelEconomyHighWay &&
                      <div className="vehicle-spec">
                        <BsFuelPump size={25} />
                        <div>
                          <div className="vehicle-spec-title">Fuel Economy HighWay</div>
                          <div className="vehicle-spec-detail">{vehicleDetail.fuelEconomyHighWay}</div>
                        </div>
                      </div>
                    }
                    <div className="vehicle-spec">
                      <GiCarDoor size={25} />
                      <div>
                        <div className="vehicle-spec-title">Doors</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.doors}</div>
                      </div>
                    </div>
                    <div className="vehicle-spec">
                      <GiCarSeat size={25} />
                      <div>
                        <div className="vehicle-spec-title">Seats</div>
                        <div className="vehicle-spec-detail">{vehicleDetail.seats}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-detail-buttons" style={{ display: 'flex', gap: '0px' }}>
                  <Button className="custom-button"><Link to={`/appointment`}>Book an appointment </Link></Button>
                  <Button className="custom-reset-button"><Link to={`/financing`}>Finance vehicle</Link></Button>

                  {/* {vehicleDetail.carFaxPDFUrl && 
                  <Button className="custom-carFax-button">
                    <Link to={`${vehicleDetail.carFaxPDFUrl}`} target="_blank">
                      <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FCarFaxLogo_1000x1000.png?alt=media&token=a6638f18-bc80-436b-a379-6901b1d99826"/>
                    </Link>
                  </Button>
                } */}
                </div>

                <div className="product-tier-container">
                  {vehicleDetail.carFaxPDFUrl &&
                    <div className="product-carfax">
                      <Link to={`${vehicleDetail.carFaxPDFUrl}`} target="_blank">
                        <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FCarFaxLogo_1000x1000.png?alt=media&token=a6638f18-bc80-436b-a379-6901b1d99826" />
                      </Link>
                    </div>}
                  {vehicleDetail.reportedAccidents &&
                    <div className="product-accident-free">
                      <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FNoReportedAccidents_1000x1000.png?alt=media&token=ad7b70c1-3257-4a60-b3af-b59d62fa1394" />
                    </div>}

                  {vehicleDetail.lowKilometers &&
                    <div className="product-low-kilometers">
                      <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FLowKilometers_1000x1000.png?alt=media&token=d23a53e2-6a75-4dd5-b5c7-f7ff913c90c2" />
                    </div>}

                  {vehicleDetail.topCondition &&
                    <div className="product-top-condition">
                      <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FTopCondition_1000x1000.png?alt=media&token=95e668b3-8701-4d55-b05a-2426e949990f" />
                    </div>}

                  {vehicleDetail.oneOwner &&
                    <div className="product-one-owner">
                      <img src="https://firebasestorage.googleapis.com/v0/b/serene-auto.appspot.com/o/pdfs%2F%20-%20%20%20%2FOneOwner_1000x1000.png?alt=media&token=aeecda7e-5f4c-454d-aef8-0048b88b70fb" />
                    </div>}
                </div>
              </div>
            </div>
          </div>
          <div id='vehicle-data-section' className='data-section'>
            {displayVehicleDataSection &&
              <div className="product-detail">
                <div className="product-financing-calculator" style={{ width: '100%' }}>
                  <div className="vehicle-title">Vehicle Data</div>
                  <div className="row">
                    {!_.isEmpty(vehicleDetail.features) && !_.isEmpty(vehicleDetail.features['Vehicle Data']) && vehicleDataProps.map(key => {

                      let prop = vehicleDetail.features['Vehicle Data'][key];
                      
                      if ((key !== 'odometer' && (!prop || !prop.show))
                        || (key == 'odometer' && !vehicleDetail.vehicleKilometers))
                        return <></>;

                      const val = key !== 'odometer' ? prop.selected
                        : nf.format(vehicleDetail.vehicleKilometers) + ' KM';

                      return (
                        <div className="col-md-4 col-sm-12">
                          <div className="d-flex flex-row">
                            <div className="key">{key}</div>
                            <div className="value">{val}</div>
                          </div>
                        </div>)
                    })}
                  </div>
                  {!_.isEmpty(vehicleDetail.features) && (!_.isEmpty(vehicleDetail.features['High Value']) || !_.isEmpty(vehicleDetail.features.Equipment)) &&
                    <React.Fragment>
                      <div className="row" style={{ paddingTop: 30 }}>
                        <div className="col-md-4">
                          <React.Fragment>
                            <div className="vehicle-title">
                              <FaRegGem />High Value Options</div>
                            {!_.isEmpty(vehicleDetail.features) && !_.isEmpty(vehicleDetail.features['High Value']) &&
                              <div className="row">
                                {Object.keys(vehicleDetail.features['High Value']).sort().map(item => {
                                  if (!vehicleDetail.features['High Value'][item].show)
                                    return <></>;
                                  return (
                                    <div className="col-md-6 col-sm-12">
                                      <div className="d-flex flex-row">
                                        {item}
                                      </div>
                                    </div>)
                                })}
                              </div>
                            }
                          </React.Fragment>
                        </div>
                        <div className="col-md-8">
                          <div className="vehicle-title">Equipment</div>
                          <div className="row">
                            {!_.isEmpty(vehicleDetail.features) && !_.isEmpty(vehicleDetail.features.Equipment) && Object.keys(vehicleDetail.features.Equipment).map(item => {
                              if (!vehicleDetail.features.Equipment[item].show)
                                return <></>;

                              return (
                                <div className="col-md-3 col-sm-12">
                                  <div className="d-flex flex-row">
                                    {item}
                                  </div>
                                </div>)
                            })}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            }
          </div>
          <div className="product-detail data-section" id="warranty-section">
            <div className="product-financing-calculator">
              <div className="vehicle-title">Warranty Options</div>
              <Button className="custom-reset-button" style={{ width: '100%' }}><Link to={`/warranty/${id}/vehicle`}>View More Details</Link></Button>
            </div>
            <div className="detail-specifications hideAfterResize"></div>
          </div>

          <div className="product-detail">
            {carPrice && <FinanceCalculator priceOfCar={carPrice}></FinanceCalculator>}
            <div className="detail-specifications hideAfterResize"></div>
          </div>

          <div className="product-detail data-section" id='detailed-features-section'>
            <div className="product-financing-calculator">

              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={
                      (event, newValue) => {
                        setSelectedTab(newValue);
                      }
                    } aria-label="Description Tab">
                    <Tab label="Specs" value="1" />
                    {vehicleDetail.description && <Tab label="Special Notes" value="2" />}
                  </TabList>
                </Box>

                <TabPanel value="1" style={{ padding: 0 }}>
                  {_.isEmpty(vehicleDetail.features) ? 'There are no specs for this vehicle' :
                    <SpecSheet vin={vehicleDetail.VIN} data={vehicleDetail.features} collapsed={collapsed} />
                  }
                  {!_.isEmpty(vehicleDetail.features) &&
                    <Button className="custom-reset-button" style={{ width: '100%' }} onClick={() => { setCollapsed(!collapsed) }}>Show {collapsed ? 'More' : 'Less'}</Button>
                  }
                </TabPanel>

                <TabPanel value="2" style={{ padding: 0 }}>
                  <textarea style={{ width: '100%' }} readOnly type="text" value={vehicleDetail.description} />
                </TabPanel>

              </TabContext>
            </div>
            <div className="detail-specifications hideAfterResize"></div>
          </div>

          <div className="product-detail">
            <ShippingCalculator></ShippingCalculator>
            <div className="detail-specifications hideAfterResize"></div>
          </div>

          {(!_.isEmpty(vehicleDetail.vehicleHighValueFeatures) || !_.isEmpty(vehicleDetail.vehicleEquipmentFeatures)) &&
            <div className="product-detail">
              <div className="product-financing-calculator">
                <div className="vehicle-title">Vehicle Features</div>
                {!_.isEmpty(vehicleDetail.vehicleHighValueFeatures) &&
                  <>
                    <div className="vehicle-feature-title">High Value Options</div>
                    <div className="vehicle-feature-specs">
                      {vehicleDetail.vehicleHighValueFeatures.map((feature, index) => (
                        <div className="vehicle-feature-spec" index={index}>{feature}</div>
                      ))}
                    </div>
                  </>
                }
                {!_.isEmpty(vehicleDetail.vehicleEquipmentFeatures) &&
                  <>
                    <div className="vehicle-feature-title">Equipment</div>
                    <div className="vehicle-feature-specs">
                      {vehicleDetail.vehicleEquipmentFeatures.map((feature, index) => (
                        <div className="vehicle-feature-spec" index={index}>{feature}</div>
                      ))}
                    </div>
                  </>
                }
              </div>
              <div className="detail-specifications hideAfterResize"></div>
            </div>
          }
        </div> :
        <div className="spinner-container">
          <div role="status" className="spinner-border spinner-styling">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default CarDetail;